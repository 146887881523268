import { ErrorOutline } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { FC } from 'react';

type BatteryProps = {
  value?: number;
};

const colorMap: { value: number; color: string }[] = [
  { value: 70, color: 'rgb(102, 187, 106)' },
  { value: 30, color: 'rgb(255, 167, 38)' },
  { value: 0, color: 'rgb(244, 67, 54)' },
];

const Battery: FC<BatteryProps> = ({ value }) => {
  const color = typeof value === 'number'
    ? colorMap.find(x => value >= x.value)?.color
    : undefined;

  return (
    <Typography variant='inherit' color={color}>
      {typeof value === 'number'
        ? <>
          {value < 30 && <ErrorOutline color="error" fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />}
          {`${value.toFixed(0)} %`}
        </>
        : '-'}
    </Typography>
  );
};

export default Battery;

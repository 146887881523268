import { Typography, Grid } from '@mui/material';
import { FC, useCallback } from 'react';
import SettingsForm from './SettingsForm';
import { toSettingsFormModel, SETTINGS_FIELDS, toAppSettings } from './SettingsForm.config';
import { useSnackbar } from 'notistack';
import { useSettings } from 'providers/SettingsProvider/useSettings';

const SettingsPage: FC = () => {
  const { settings, setSettings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback((form: SETTINGS_FIELDS) => {
    const isUpdated = setSettings({
      ...settings,
      ...toAppSettings(form),
    });
    if (isUpdated) {
      enqueueSnackbar('App Settings are updated', { variant: 'success' });
    }
  }, [settings, setSettings, enqueueSnackbar]);

  return (
    <Grid container spacing={1} px={1}>
      <Grid item xs={12}>
        <Typography variant="h6">Authentication</Typography>
      </Grid>
      <Grid item xs={12}>
        <SettingsForm
          onSubmit={onSubmit}
          defaultValues={toSettingsFormModel(settings)}
        />
      </Grid>
    </Grid>
  );
};

export default SettingsPage;

/* eslint-disable no-mixed-operators */
import React, { CSSProperties, FC, useMemo } from 'react';

type PowerMeterProps = {
  height: number;
  width: number;
  ticks: number;
  minValue: number;
  maxValue: number;
  value: number | undefined;
  minRangeValue: number;
  maxRangeValue: number;
  valueFormatter: (value: number, isCurrent?: boolean) => string;
};

const styles: Record<'root' | 'label' | 'value', CSSProperties> = {
  root: {
    userSelect: 'none',
    fontSize: 11,
    fontWeight: 'bold',
  },
  label: {
    fill: 'white',
    textAnchor: 'middle',
    alignmentBaseline: 'central',
  },
  value: {
    fill: 'orange',
    alignmentBaseline: 'central',
  },
};

type Point = [number, number];

const point = (x: number, y: number): Point => [x, y];
const normalize = (value: number, min: number, max: number) => Math.min(Math.max(min, value), max);
const translate = (points: Point[], offset: number) => points.map(([x, y]) => point(x, y + offset));
const pointsToPath = (points: Point[]) => [points.map(
  ([x, y], iPoint) => [(iPoint > 0 ? 'L' : 'M'), x, ' ', y].join(''),
).join(' '), 'Z'].join('');

const PowerMeter: FC<PowerMeterProps> = (props) => {
  const { minRangeValue, maxRangeValue, minValue, maxValue, ticks, value, width, height, valueFormatter } = props;
  const w2 = width / 2;

  const { min, current, max } = useMemo(() => {
    const rangeValue = maxValue - minValue;
    const min = normalize(Math.round((Math.min(minRangeValue, maxRangeValue) - minValue) / rangeValue * ticks), 0, ticks - 1);
    const max = normalize(Math.round((Math.max(minRangeValue, maxRangeValue) - minValue) / rangeValue * ticks), 0, ticks - 1);
    const current = typeof value === 'number'
      ? (normalize(Math.round((value - minValue) / rangeValue * ticks), 0, ticks - 1))
      : null;

    return { min, current, max };
  }, [minRangeValue, maxRangeValue, value, maxValue, minValue, ticks]);

  const { tickPoints, tickPointsLarge } = useMemo(() => ({
    tickPoints: [point(4, 0), point(w2 - 4, 0), point(w2 - 4, 1), point(4, 1)],
    tickPointsLarge: [point(0, 0), point(w2, 0), point(w2, 1), point(0, 1)],
  }), [w2]);

  const distance = Math.ceil(height / ticks);

  const ticksPath = Array(ticks).fill(null).map((_, iTick)=> {
    const isLarge = iTick === min || iTick === max;
    const isActive = iTick >= min && iTick <= max;
    const isCurrent = current === iTick;

    return React.createElement('path', {
      key: ['tick-', iTick].join(''),
      d: pointsToPath(
        translate(((isLarge || isCurrent) ? tickPointsLarge : tickPoints), (height - 1) - iTick * distance),
      ),
      style: {
        fill: isCurrent ? 'orange' : (isActive ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.3)'),
      },
    });
  });

  const labels = useMemo(() => {
    const minRangeLabelPos = min > 0 ? ['min', minRangeValue, point(w2 / 2, (height - 1) - (min ?? 0) * distance + 8)] : null;
    const maxRangeLabelPos = ['max', maxRangeValue, point(w2 / 2, (height - 1) - (max ?? 0) * distance - 8)];
    const valueLabelPos = ['value', value ?? 0, point(w2 + 2, Math.min(height - 4, (height - 1) - (current ?? 0) * distance))];

    return [minRangeLabelPos, valueLabelPos, maxRangeLabelPos].filter(Boolean) as ['min' | 'max' | 'value', number, Point][];
  }, [current, distance, height, min, max, w2, value, minRangeValue, maxRangeValue]);

  return (
    <svg width={width} height={height} style={styles.root}>
      <g>{ticksPath}</g>
      {labels.map(([type, value, point]) => (
        <text key={type} x={point[0]} y={point[1]} style={type === 'value' ? styles.value : styles.label}>
          {valueFormatter(value, type === 'value')}
        </text>
      ))}
    </svg>
  );
};

export default PowerMeter;

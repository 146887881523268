import { FC } from 'react';

type TemperatureProps = {
  value?: number;
};

const Temperature: FC<TemperatureProps> = ({ value }) => {
  return (
    <>
      {typeof value === 'number' ? `${value.toFixed(1)} °C` : '-'}
    </>
  );
};

export default Temperature;

import { Box } from '@mui/material';
import SettingsPage from 'pages/SettingsPage';
import { FC } from 'react';

const SettingsSubPage: FC = () => {
  return (
    <Box mt={2}>
      <SettingsPage />
    </Box>
  );
};

export default SettingsSubPage;

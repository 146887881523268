import { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useConnection } from '../../../../providers/ConnectionProvider';

const ConnectionIndicator: FC = () => {
  const { status } = useConnection();

  return (
    <>
      {status === 'offline' && <WifiOffIcon fontSize='small' />}
      {status === 'direct' && <CheckCircleIcon fontSize='small' color="success" />}
      {status === 'online' && <CheckCircleOutlineIcon fontSize='small' color="success" />}
    </>
  );
};

export default ConnectionIndicator;

import { Accessory } from '@smart-home/common';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { DialogComponentProps, useFormDialog } from 'hooks/useFormDialog';
import { ElementWithProps } from 'types';
import { useConnection } from 'providers/ConnectionProvider';

type CreateAccessoryOptions<TModel> = {
  defaultValues: TModel;
};

export const useCreateAccessory = <TModel extends Partial<Accessory>, >(): [ElementWithProps<DialogComponentProps<TModel>>, (options: CreateAccessoryOptions<TModel>) => void, boolean] => {
  const { apiUrl } = useConnection();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: addSensor } = useMutation<Accessory, AxiosError<{ message: string }>, TModel>(async (values) =>
    axios.put<Accessory>(`${apiUrl}/accessories`, values).then(x => x.data), {
    onSuccess: (accessory) => {
      const accessories = queryClient.getQueryData<Accessory[]>('accessories') ?? [];
      queryClient.setQueryData('accessories', [...accessories, accessory]);
    },
    onError: (error) => {
      const msg = error.response?.data?.message;
      if (msg && msg.includes('E11000')) {
        enqueueSnackbar('Accessory with same options is already existed', { variant: 'error' });
        return;
      }
      enqueueSnackbar('Unknown error', { variant: 'error' });
    },
  });

  const [[CreateForm, createFormProps], showCreateForm] = useFormDialog<TModel>({}, addSensor);

  const onCreate = useCallback((options: CreateAccessoryOptions<TModel>) => {
    showCreateForm(options.defaultValues);
  }, [showCreateForm]);

  const createModalProps = useMemo<DialogComponentProps<TModel>>(() => ({
    ...createFormProps,
    title: 'Add Accessory',
    maxWidth: 'sm',
    fullWidth: true,
  }), [createFormProps]);

  return [[CreateForm, createModalProps], onCreate, createModalProps.open];
};

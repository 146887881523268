import { Typography, Grid, Stack, Box } from '@mui/material';
import { PlayerCommand, MediaItem } from '@smart-home/common';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDataProvider } from 'providers';
import PlaySpinner from './PlaySpinner';
import FavoriteMenu, { FavoriteMenuProps } from './FavoriteMenu';

export const addVideo = (value: MediaItem) => {
  const videos = getVideos();
  if (videos.find(x => x.id === value.id)) {
    return;
  }

  localStorage.setItem('FAVORITES', JSON.stringify([value, ...videos]));
};

export const removeVideo = (id: string) => {
  const videos = getVideos().filter(x => x.id !== id);
  localStorage.setItem('FAVORITES', JSON.stringify(videos));
  return videos;
};

const getVideos = () => {
  return JSON.parse(localStorage.getItem('FAVORITES') ?? '[]') as MediaItem[];
};

export const isFavorite = (id?: string) => {
  return getVideos().some(x => x.id === id);
};

const LibrarySubPage: FC<{isActive: boolean}> = ({ isActive }) => {
  const [items, setItems] = useState<MediaItem[]>([]);

  useEffect(() => {
    if (isActive) {
      setItems(getVideos());
    }
  }, [isActive]);

  const [selectedVideo, setSelectedVideo] = useState<string>();
  const { playerData, sendWsMessage } = useDataProvider();

  const scrollContainerRef = useRef<HTMLDivElement>();

  const { status, source } = playerData;

  useEffect(() => {
    setSelectedVideo(source?.id);
  }, [source]);

  const sendPlayerCommand = useCallback((data: PlayerCommand) => {
    sendWsMessage('player/command', data);
  }, [sendWsMessage]);

  const onAction = useCallback<FavoriteMenuProps['onAction']>((type, id) => {
    if (type === 'play') {
      setSelectedVideo(id);
      const currentIndex = items.findIndex(x => x.id === id);
      const next = items.slice(currentIndex + 1);
      sendPlayerCommand({ type, media: items[currentIndex], next });
    } else if (type === 'favorite') {
      setItems(removeVideo(id));
    }
  }, [sendPlayerCommand, items]);

  return (
    <Grid container spacing={1} mt={0.5}>
      <Grid item xs={12}>
        <Box maxHeight="calc(100vh - 90px)" overflow="auto" py={0.5} ref={scrollContainerRef}>
          {items.map((x) => (
            <Stack
              key={x.id}
              direction="row"
              sx={{ mb: 0.5, cursor: 'pointer', outline: `2px solid ${x.id === selectedVideo ? '#ffffff5e' : undefined}` }}
              spacing={1}
            >
              <Box minWidth={100} width={100} height={56} position="relative" onClick={() => onAction('play', x.id)}>
                <Box sx={{ backgroundImage: `url(${x.thumbnail})`, backgroundSize: 'contain' }} width="100%" height={56} />
                <Typography variant="caption" sx={{ position: 'absolute', right: 0, bottom: 0, backgroundColor: 'black', lineHeight: 1, padding: 0.2, fontSize: 10, borderRadius: '2px' }}>
                  {x.duration}
                </Typography>
                {x.id === source?.id && <PlaySpinner active={status?.state === 'playing'} zIndex={1} position="absolute" width="100%" top={0} height={30} />}
              </Box>
              <Box flexGrow={1} onClick={() => onAction('play', x.id) }>
                <Typography variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 'calc(100vw - 180px)', textOverflow: 'ellipsis' }}>{x.title}</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="caption">{x.views} • {x.published}</Typography>
                </Stack>
              </Box>
              <Box minWidth={40} pt={1} pr={0.5}>
                <FavoriteMenu data={x} onAction={onAction} />
              </Box>
            </Stack>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LibrarySubPage;

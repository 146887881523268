import axios from 'axios';
import { ElementType, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import { Add, SvgIconComponent, Thermostat, EmojiObjects, Power, CameraAlt } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { Accessory, AccessoryType, BOILER, CAMERA, SOLID_RELAY, SONOFF_SWITCH, TEMP_SENSOR, TUYA_TEMP_SENSOR, UPS, XIAOMI_TEMP_SENSOR } from '@smart-home/common';
import Table, { Cell, Header, useRowSelect } from 'components/Table';
import { AccessoryProps } from './accessories/accessories.types';
import { useCreateAccessory } from './hooks/useCreateAccessory';
import { useDataProvider } from 'providers';
import { TempSensorAccessoryTable, TempSensorAccessoryForm } from './accessories/TempSensorAccessory';
import { XiaomiTempSensorAccessoryForm, XiaomiTempSensorAccessoryTable } from './accessories/XiaomiTempSensorAccessory';
import { RelayAccessoryForm, RelayAccessoryTable } from './accessories/RelayAccessory';
import { TuyaTempSensorAccessoryForm, TuyaTempSensorAccessoryTable } from './accessories/TuyaTempSensorAccessory';
import { SonoffSwitchAccessoryForm, SonoffSwitchAccessoryTable } from './accessories/SonoffSwitchAccessory';
import { BoilerAccessoryForm, BoilerAccessoryTable } from './accessories/BoilerAccessory';
import { useConnection } from 'providers/ConnectionProvider';
import { PowerAccessoryForm, PowerAccessoryTable } from './accessories/PowerAccessory';
import { SurveillanceAccessoryForm, SurveillanceAccessoryTable } from './accessories/SurveillanceAccessory';
import { SurveillanceAccessoryFormModel } from './accessories/SurveillanceAccessory/SurveillanceAccessory.form';

type AccessoryRow<T extends Accessory = Accessory> = {
  name: ReactNode;
  icon?: SvgIconComponent;
  defaultValues: Partial<T>;
  tableComponent: ElementType<AccessoryProps<T>>;
  createForm: ElementType;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const accessoryTypes: Partial<Record<AccessoryType, AccessoryRow<any>>> = {
  [BOILER]: {
    name: 'Boiler',
    icon: Thermostat,
    tableComponent: BoilerAccessoryTable,
    defaultValues: { topic: 'boiler', id: '0', alias: 'thermostat', type: BOILER },
    createForm: BoilerAccessoryForm,
  },
  [UPS]: {
    name: 'UPS',
    icon: Power,
    tableComponent: PowerAccessoryTable,
    defaultValues: { topic: 'ups', id: '', alias: '', type: UPS },
    createForm: PowerAccessoryForm,
  },
  [TEMP_SENSOR]: {
    name: 'DS18B20 Temp Sensors',
    icon: Thermostat,
    tableComponent: TempSensorAccessoryTable,
    defaultValues: { topic: '', id: '', alias: '', type: TEMP_SENSOR },
    createForm: TempSensorAccessoryForm,
  },
  [XIAOMI_TEMP_SENSOR]: {
    name: 'Xiaomi Temp Sensors',
    icon: Thermostat,
    tableComponent: XiaomiTempSensorAccessoryTable,
    defaultValues: { topic: 'zigbee', id: '', alias: '', type: XIAOMI_TEMP_SENSOR },
    createForm: XiaomiTempSensorAccessoryForm,
  },
  [TUYA_TEMP_SENSOR]: {
    name: 'Tuya Temp Sensors',
    icon: Thermostat,
    tableComponent: TuyaTempSensorAccessoryTable,
    defaultValues: { topic: 'zigbee', id: '', alias: '', type: TUYA_TEMP_SENSOR },
    createForm: TuyaTempSensorAccessoryForm,
  },
  [SOLID_RELAY]: {
    name: 'Solid Relays',
    icon: EmojiObjects,
    tableComponent: RelayAccessoryTable,
    defaultValues: { topic: '', id: '', alias: '', type: SOLID_RELAY },
    createForm: RelayAccessoryForm,
  },
  [SONOFF_SWITCH]: {
    name: 'Sonoff Switch',
    icon: EmojiObjects,
    tableComponent: SonoffSwitchAccessoryTable,
    defaultValues: { topic: 'stat', cmdTopic: 'cmnd', id: '', alias: '', type: SONOFF_SWITCH },
    createForm: SonoffSwitchAccessoryForm,
  },
  [CAMERA]: {
    name: 'Cameras',
    icon: CameraAlt,
    tableComponent: SurveillanceAccessoryTable,
    defaultValues: { topic: 'camera', id: '', alias: '', type: CAMERA, host: '192.168.1.', port: '' } as SurveillanceAccessoryFormModel,
    createForm: SurveillanceAccessoryForm,
  },
};

const AccessoriesPage: FC = () => {
  const { apiUrl } = useConnection();
  const { data: accessoriesData } = useDataProvider();
  const { data } = useQuery('accessories', () => axios.get<Accessory[]>(`${apiUrl}/accessories`).then(x => x.data), {
    enabled: !!apiUrl,
  });
  const [selectedType, setSelectedType] = useState<AccessoryType>();
  const [[CreateComponent, createComponentProps], onCreateAccessory, isCreateDialogOpen] = useCreateAccessory();
  const [accessoryTypeForCreation, setAccessoryTypeForCreation] = useState<AccessoryType>();

  const onSelect = useRowSelect(
    { nodes: data ?? [] },
    {
      onChange: ({ payload }) => {
        setSelectedType(payload?.id);
      },
    },
  );

  const [components, accessoryProps] = useMemo<[AccessoryRow | null, Pick<AccessoryProps, 'accessories'>]>(() => {
    const AccessoryComponent = selectedType
      ? accessoryTypes[selectedType] ?? null
      : null;

    const accessoryProps = {
      accessories: data?.filter(x => x.type === selectedType) ?? [],
    };

    return [AccessoryComponent, accessoryProps];
  }, [selectedType, data]);

  const { tableComponent: AccessoryComponent, name: tableName } = components ?? {};

  const tableRows = useMemo(() => Object.keys(accessoryTypes).map(id => ({
    id,
    count: data?.filter(x => x.type === id).length ?? 0,
    name: accessoryTypes[id as AccessoryType]?.name,
    icon: accessoryTypes[id as AccessoryType]?.icon,
  })), [data]);

  useEffect(() => {
    if (!isCreateDialogOpen) {
      setAccessoryTypeForCreation(undefined);
    }
  }, [isCreateDialogOpen]);

  useEffect(() => {
    if (accessoryTypeForCreation) {
      onCreateAccessory({
        defaultValues: accessoryTypes[accessoryTypeForCreation]!.defaultValues,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessoryTypeForCreation]);

  return (
    <>
      <Grid container px={2} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Accessories
          </Typography>
        </Grid>
        <Grid xs={12} item pb={2}>
          <Table
          grid="1fr 80px 60px"
          select={onSelect}
          data={tableRows}
          headers={<>
            <Header>Accessory</Header>
            <Header>Count</Header>
            <Header />
          </>}
          row={({ row: { id, name, count, icon: Icon } }) => (
            <>
              <Cell>
                <>
                  {Icon && <Icon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} /> }
                  {name}
                </>
              </Cell>
              <Cell>{count}</Cell>
              <Cell onClick={e => e.stopPropagation()}>
                <IconButton onClick={() => setAccessoryTypeForCreation(id as AccessoryType)}>
                  <Add fontSize="small" />
                </IconButton>
              </Cell>
            </>
          )}
          />
        </Grid>
        {AccessoryComponent && (
          <>
            <Grid xs={12} item>
              <Typography variant="h2" gutterBottom ml={2}>
                {tableName}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <AccessoryComponent {...accessoryProps} data={accessoriesData} />
            </Grid>
          </>
        )}
      </Grid>
      <CreateComponent
        {...createComponentProps}
        formComponent={accessoryTypeForCreation ? accessoryTypes[accessoryTypeForCreation]?.createForm : undefined}
      />
    </>
  );
};

export default AccessoriesPage;

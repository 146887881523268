import { Card, CardContent, Typography, Grid, Button, LinearProgress, TextField, Divider } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useDataProvider } from 'providers';

const sync = async (sendMessage: (topic: string, payload: string) => void) => {
  for (let i = 0; i < 18; i += 1) {
    await new Promise(resolve => {
      sendMessage(`h_relays/${i}/get`, '');
      setTimeout(resolve, 100);
    });
  }
  for (let i = 0; i < 6; i += 1) {
    await new Promise(resolve => {
      sendMessage(`h_sensors/${i}/get`, '');
      setTimeout(resolve, 100);
    });
  }
};

const ServicePage: FC = () => {
  const { sendMqttMessage } = useDataProvider();
  const [inProgress, setInProgress] = useState(false);
  const [command, setCommand] = useState('');

  const onRequestUpdate = useCallback(async () => {
    setInProgress(true);
    await sync(sendMqttMessage);
    setInProgress(false);
  }, [sendMqttMessage]);

  const onSend = useCallback(() => {
    const [topic, payload = ''] = command.split(';');
    sendMqttMessage(topic, payload);
    setCommand('');
  }, [command, sendMqttMessage]);

  return (
    <Grid container px={2} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Service
        </Typography>
        <LinearProgress style={{ visibility: inProgress ? 'visible' : 'hidden' }} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button variant="contained" onClick={onRequestUpdate}>
                  Request Device Update
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth value={command} label="Mqtt message (topic;payload)" onChange={(e) => {
                  setCommand(e.target.value);
                }}
                />
                <Button onClick={onSend} disabled={!command.length}>Send</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ServicePage;

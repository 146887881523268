import { Box, Stack, Typography } from '@mui/material';
import { MediaItem, Status } from '@smart-home/common';
import { FC, memo } from 'react';
import PlaySpinner from './PlaySpinner';
import VideoMenu, { VideoMenuProps } from './VideoMenu';

type MediaItemsListProps = {
  items: MediaItem[];
  onAction: VideoMenuProps['onAction'];
  statusState?: Status['state'];
  selectedMediaId?: string;
  currentMediaId?: string;
};

const MediaItemsList: FC<MediaItemsListProps> = ({ items, onAction, statusState, currentMediaId, selectedMediaId }) => {
  return (
    <>
      {items.map((x) => (
        <Stack
          key={x.id}
          direction="row"
          sx={{ mb: 0.5, cursor: 'pointer', outline: `2px solid ${x.id === selectedMediaId ? '#ffffff5e' : undefined}` }}
          spacing={1}
        >
          <Box minWidth={100} width={100} height={56} position="relative" onClick={() => onAction('play', x)}>
            <Box sx={{ backgroundImage: `url(${x.thumbnail})`, backgroundSize: 'contain' }} width="100%" height={56} />
            <Typography variant="caption" sx={{ position: 'absolute', right: 0, bottom: 0, backgroundColor: 'black', lineHeight: 1, padding: 0.2, fontSize: 10, borderRadius: '2px' }}>
              {x.duration}
            </Typography>
            {x.id === currentMediaId && <PlaySpinner active={statusState === 'playing'} zIndex={1} position="absolute" width="100%" top={0} height={30} />}
          </Box>
          <Box flexGrow={1} onClick={() => onAction('play', x) }>
            <Typography variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 'calc(100vw - 180px)', textOverflow: 'ellipsis' }}>{x.title}</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption">{x.views} • {x.published}</Typography>
            </Stack>
          </Box>
          <Box minWidth={40} pt={1} pr={0.5}>
            <VideoMenu data={x} onAction={onAction} />
          </Box>
        </Stack>
      ))}
    </>
  );
};

export default memo(MediaItemsList);

import { FC } from 'react';
import { BoxProps, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

type PlaySpinnerProps = {
  active?: boolean;
} & BoxProps;

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
  },
  root: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: 50,
    width: 50,
    margin: '-25px 0 0 -25px',

    '& span': {
      position: 'absolute',
      display: 'block',
      bottom: 10,
      width: 9,
      height: 5,
      background: 'rgba(0, 0, 0, 0.25)',
      animation: '$bars1 2.5s infinite ease-in-out',
    },
    '& span:nth-child(2)': {
      left: 11,
      animationDelay: '0.2s',
    },
    '& span:nth-child(3)': {
      left: 22,
      animationDelay: '0.4s',
    },
    '& span:nth-child(4)': {
      left: 33,
      animationDelay: '0.6s',
    },
    '& span:nth-child(5)': {
      left: 44,
      animationDelay: '0.8s',
    },
  },
  '@keyframes bars1': {
    '0%': {
      height: 5,
      transform: 'translateY(0px)',
      background: 'rgba(0, 0, 0, 0.25)',
    },
    '25%': {
      height: 30,
      transform: 'translateY(15px)',
      background: '#000000',
    },
    '50%': {
      height: 5,
      transform: 'translateY(0px)',
      background: 'rgba(0, 0, 0, 0.25)',
    },
    '100%': {
      height: 5,
      transform: 'translateY(0px)',
      background: 'rgba(0, 0, 0, 0.25)',
    },
  },
}), { name: 'PlaySpinner' });

const PlaySpinner: FC<PlaySpinnerProps> = ({ active = true, ...props }) => {
  const classNames = useStyles();

  if (!active) return null;

  return (
    <Box className={classNames.wrapper} {...props}>
      <Box className={classNames.root}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Box>
    </Box>
  );
};

export default PlaySpinner;

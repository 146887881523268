import { FormControlLabel, FormControlLabelProps, Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material';
import { FC } from 'react';

type SwitchProps = {
  checked: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  switchProps?: Omit<MuiSwitchProps, 'checked' | 'disabled' | 'onChange'>;
} & Omit<FormControlLabelProps, 'control' | 'onChange'>;

const Switch: FC<SwitchProps> = ({ disabled, checked, onChange, switchProps, ...props }) => {
  return (
    <FormControlLabel
      {...props}
      control={
        <MuiSwitch
          {...switchProps}
          checked={checked}
          disabled={disabled}
          onChange={(_, checked) => onChange?.(checked)}
        />
      }
    />
  );
};

export default Switch;

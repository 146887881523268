import { createContext } from 'react';

export type ConnectionStatus = 'offline' | 'direct' | 'online';

export type Connection = {
  status: ConnectionStatus;
  wsStatus: boolean;
  apiUrl: string | null;
  connectInProgress: boolean;
  checkConnection: () => Promise<ConnectionStatus>;
  setWsStatus: (isConnected: boolean) => void;
};

const defaultConnectionContext: Connection = {
  status: 'offline',
  wsStatus: false,
  apiUrl: null,
  connectInProgress: false,
  checkConnection: async () => 'offline',
  setWsStatus: () => null,
};

const ConnectionContext = createContext<Connection>(defaultConnectionContext);
ConnectionContext.displayName = 'ConnectionContext';

export default ConnectionContext;

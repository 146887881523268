import { FC, useMemo } from 'react';
import Table, { Cell, Header } from 'components/Table';
import { AccessoryProps } from '../accessories.types';

const PowerAccessoryTable: FC<AccessoryProps> = ({ accessories, data }) => {
  const powerConfig = accessories?.[0];
  const powerData = data?.UPS?.[0];

  const tableData = useMemo(
    () => powerConfig ? [
      { id: 'topic', name: 'Topic', value: `${powerConfig.topic}/${powerConfig.id}` },
      { id: 'enable', name: 'State', value: powerData?.value?.STATUS, editable: false, type: 'string', rawValue: powerData?.value?.STATUS },
    ] : [],
    [powerConfig, powerData],
  );

  return (
    <>
      <Table
        grid="200px minmax(0, 1fr) 30px"
        data={tableData}
        headers={<>
          <Header stiff>PARAMETER</Header>
          <Header resize>VALUE</Header>
          <Header />
        </>}
        row={({ row }) => (
          <>
            <Cell>{row.name}</Cell>
            <Cell>{row.value}</Cell>
            <Cell />
          </>
        )}
      />
    </>
  );
};

export default PowerAccessoryTable;

import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';
import { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { ElementWithProps } from '../types';

export type ConfirmDialogComponentProps = {
  title?: ReactNode;
  onConfirm: () => void;
  onCancel: (_?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => void;
} & DialogProps;

const DialogComponent: FC<ConfirmDialogComponentProps> = ({ title, onConfirm, onCancel, ...props }) => (
  <Dialog {...props}>
    <DialogTitle>Confirmation</DialogTitle>
    <DialogContent>
      <Typography>{title}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onConfirm} color="warning">Confirm</Button>
    </DialogActions>
  </Dialog>
);

export const useConfirm = (): [ElementWithProps<ConfirmDialogComponentProps>, (onSuccess: () => void) => void ] => {
  const [open, setOpen] = useState(false);
  const resultRef = useRef<() => void>();

  const showDialog = useCallback((onSuccess: () => void) => {
    resultRef.current = onSuccess;
    setOpen(true);
  }, []);

  const onCancel = useCallback((_?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  }, []);

  const onConfirm = useCallback(() => {
    setOpen(false);
    resultRef.current?.();
  }, []);

  return [[DialogComponent, { open, onCancel, onConfirm }], showDialog];
};

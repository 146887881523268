import { Grid, TextField, TextFieldProps } from '@mui/material';
import { AccessoryType } from '@smart-home/common';
import { FC, useEffect } from 'react';
import { FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form';

type BoilerAccessoryProps = {
  field?: string;
} & UseFormReturn<SurveillanceAccessoryFormModel>;

const muiErrors = <T extends FieldValues>(errors: FieldErrors<T>, name: keyof T): Pick<TextFieldProps, 'error' | 'helperText'> => ({
  error: !!errors[name],
  helperText: errors[name]?.message as string,
});

export type SurveillanceAccessoryFormModel = {
  alias: string;
  topic: string;
  id: string;
  host: string;
  port: string;
  type: AccessoryType;
};

const getId = (host: string, port: string) => {
  return `0x${host.replace(/\./g, '')}${port}`;
};

const SurveillanceAccessoryForm: FC<BoilerAccessoryProps> = (props) => {
  const { register, formState: { errors }, watch, setValue } = props;

  const [host, port] = watch(['host', 'port']);
  const id = getId(host, port);

  useEffect(() => {
    setValue('id', id);
  }, [id, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Alias"
          fullWidth
          error={!!errors.alias}
          helperText={errors.alias?.message}
          autoComplete="off"
          {...muiErrors(errors, 'alias')}
          {...register('alias')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Topic"
          fullWidth
          error={!!errors.topic}
          helperText={errors.topic?.message}
          autoComplete="off"
          disabled
          {...muiErrors(errors, 'topic')}
          {...register('topic')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Id"
          fullWidth
          disabled
          autoComplete="off"
          value={id}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          label="Host"
          fullWidth
          error={!!errors.id}
          helperText={errors.id?.message}
          autoComplete="off"
          {...muiErrors(errors, 'id')}
          {...register('host')}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Port"
          fullWidth
          error={!!errors.id}
          helperText={errors.id?.message}
          autoComplete="off"
          {...muiErrors(errors, 'id')}
          {...register('port')}
        />
      </Grid>
    </Grid>
  );
};

export default SurveillanceAccessoryForm;

import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Paper, Switch, TextField } from '@mui/material';
import { useFormDialog } from 'hooks/useFormDialog';
import { ElementType, FC, useCallback, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { bool, number, object, ObjectSchema, string } from 'yup';

export type Parameter = {
  id: string;
  name: string;
  value: unknown;
  type: 'string' | 'number' | 'boolean';
};

type ParameterUpdateDialogProps = {
  parameter: Parameter | null;
  onClose: () => void;
  onSave: (field: string, value: unknown) => void;
};

const BooleanParameter: FC<UseFormReturn> = ({ control }) => {
  return (
    <Paper sx={{ p: 1 }}>
      <FormControlLabel
        label="Value"
        control={
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <Switch
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        }
      />
    </Paper>
  );
};

const StringParameter: FC<UseFormReturn> = ({ register }) => {
  return (
    <Paper sx={{ p: 1 }}>
      <TextField
        label="Value"
        fullWidth
        autoComplete="off"
        {...register('value')}
      />
    </Paper>
  );
};

const NumberParameter: FC<UseFormReturn> = ({ register }) => {
  return (
    <Paper sx={{ p: 1 }}>
      <TextField
        label="Value"
        fullWidth
        type="number"
        InputProps={{
          inputProps: {
            type: 'number',
            step: '0.1',
            max: 100,
            min: 10,
          },
        }}
        autoComplete="off"
        {...register('value')}
      />
    </Paper>
  );
};

export const stringSchema: ObjectSchema<{}> = object({
  value: string()
    .trim()
    .required(),
});

export const numberSchema: ObjectSchema<{}> = object({
  value: number()
    .required(),
});

export const booleanSchema: ObjectSchema<{}> = object({
  value: bool()
    .required(),
});

const formComponentMap: Record<Parameter['type'], [ElementType<UseFormReturn>, ObjectSchema<{}>]> = {
  'boolean': [BooleanParameter, booleanSchema],
  'string': [StringParameter, stringSchema],
  'number': [NumberParameter, numberSchema],
};

const ParameterUpdateDialog: FC<ParameterUpdateDialogProps> = ({ parameter, onSave }) => {
  const updateValue = useCallback(async (form: Parameter) => {
    onSave(form.id, form.value);
  }, [onSave]);

  const type = parameter?.type;
  const [FormComponent, schema] = type ? formComponentMap[type] : [];

  const [[Form, formProps], showForm] = useFormDialog<Parameter>({
    resolver: schema && yupResolver(schema),
  }, updateValue);
  const { onClose } = formProps;

  useEffect(() => {
    if (parameter) {
      showForm(parameter);
    } else {
      onClose();
    }
  }, [parameter, showForm, onClose]);

  return (
    <Form
      {...formProps}
      title={`Update ${parameter?.name}`}
      maxWidth="xs"
      fullWidth
      formComponent={FormComponent}
    />
  );
};

export default ParameterUpdateDialog;

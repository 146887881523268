import { Typography } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Switch from 'components/Switch';
import { useDataProvider } from '../../../../providers';

type PermitJoinProps = {
  onDiscovery: (deviceId: string) => void;
};

const PermitJoin: FC<PermitJoinProps> = ({ onDiscovery }) => {
  const { sendMqttMessage, subscribe } = useDataProvider();
  const [permitJoin, setPermitJoin] = useState(false);
  const [device, setDevice] = useState<any>();
  const permitRef = useRef(permitJoin);
  const unsubscribeRef = useRef<() => void>();
  const onDiscoveryRef = useRef(onDiscovery);
  onDiscoveryRef.current = onDiscovery;

  const setPermitState = useCallback((enable: boolean) => {
    if (enable) {
      unsubscribeRef.current = subscribe('zigbee/bridge/event', (_, payload: string) => {
        const data = JSON.parse(payload);
        // {"type":"device_interview","data":{"friendly_name":"0x90fd9ffffe6494fc","status":"successful","ieee_address":"0x90fd9ffffe6494fc","supported":true}}
        if (data.type === 'device_interview' && data.data.status === 'successful' && data.data.supported) {
          setDevice(data);
          onDiscoveryRef.current(data.data.ieee_address);
        }
      });
    } else {
      unsubscribeRef.current?.();
      unsubscribeRef.current = undefined;
      setDevice(undefined);
    }
    permitRef.current = enable;
    setPermitJoin(enable);
    sendMqttMessage('zigbee/bridge/request/permit_join', enable ? 'true' : 'false');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (permitRef.current) {
        sendMqttMessage('zigbee/bridge/request/permit_join', 'false');
        unsubscribeRef.current?.();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Switch
        label="Permit join"
        checked={permitJoin}
        onChange={setPermitState}
      />
      {device && (
        <Typography variant="caption" component="p">
          [{device.data.definition.vendor}] {device.data.definition.description}, {device.data.definition.model}, {device.data.ieee_address}
        </Typography>
      )}
    </>
  );
};

export default PermitJoin;

import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AppHeader from './components/AppHeader';

let isLastPathLoaded = false;

const Root: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname ?? '/';

  useEffect(() => {
    if (!isLastPathLoaded) {
      isLastPathLoaded = true;
      const lastPathname = localStorage.getItem('pathname');
      if (lastPathname && lastPathname !== window.location.pathname) {
        navigate(lastPathname);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem('pathname', pathname);
  }, [pathname]);

  return (
    <>
      <AppHeader />
      <Outlet />
    </>
  );
};

export default Root;

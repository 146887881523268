import { Typography, TypographyProps } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';

type TemperatureProps = {
  value?: number;
} & TypographyProps;

const Temperature: FC<TemperatureProps> = ({ value, ...props }) => {
  const [temp, setTemp] = useState<number | null>(null);
  const lastUpdate = useRef(0);
  const [direction, setDirection] = useState<'up' | 'down' | 'none'>('none');

  useEffect(() => {
    if (typeof value !== 'undefined' && value !== temp) {
      if (temp !== null) {
        setDirection(value > temp ? 'up' : 'down');
        lastUpdate.current = new Date().getTime();
      }
      setTemp(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (lastUpdate.current && new Date().getTime() - lastUpdate.current > 10_000) {
        setDirection('none');
        lastUpdate.current = 0;
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const color = direction === 'up' ? '#e96969' : (direction === 'down' ? '#60b1d0' : undefined);

  return (
    <Typography color={color} whiteSpace="nowrap" component="span" fontSize="inherit" fontFamily="inherit" position="relative">
      <Typography component="span" fontSize="inherit" fontFamily="inherit" fontWeight="medium" {...props}>
        {temp?.toFixed(1)}{temp !== null ? '°' : '-'}
      </Typography>
      <Typography component="span" position="absolute" pl={0.2} sx={{ verticalAlign: 'bottom' }} fontSize="inherit" fontFamily="inherit" fontWeight="medium">
        {direction === 'up' && ' ↑'}
        {direction === 'down' && ' ↓'}
      </Typography>
    </Typography>
  );
};

export default Temperature;

import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Accessory, TuyaTempSensorValue, TUYA_TEMP_SENSOR } from '@smart-home/common';
import { FC, useMemo } from 'react';
import Battery from 'components/Battery';
import Humidity from 'components/Humidity';
import Table, { Cell, Header } from 'components/Table';
import Temperature from 'components/Temperature';
import UpdatedAt from '../../components/UpdatedAt';
import { useUpdateAccessory } from '../../hooks/useUpdateAccessory';
import { AccessoryProps } from '../accessories.types';
import { getValue } from '../accessories.utils';
import TuyaTempSensorAccessoryForm from './TuyaTempSensorAccessory.form';

const TuyaTempSensorAccessoryTable: FC<AccessoryProps> = ({ accessories, data }) => {
  const [[UpdateForm, updateFormProps], onUpdateAccessory] = useUpdateAccessory<Accessory>();
  const rows = useMemo(() => accessories.map(x => ({
    ...x,
    data: getValue<TuyaTempSensorValue>(TUYA_TEMP_SENSOR, x.id, data),
  })), [accessories, data]);

  return (
    <>
      <Table
        grid="100px repeat(1, minmax(0, 1fr)) 180px 80px 100px 80px 60px"
        data={rows}
        headers={<>
          <Header stiff>ALIAS</Header>
          <Header resize>TOPIC</Header>
          <Header>UPDATED</Header>
          <Header>BATTERY</Header>
          <Header>HUMIDITY</Header>
          <Header>TEMP</Header>
          <Header />
        </>}
        row={({ row }) => (
          <>
            <Cell stiff>{row.alias}</Cell>
            <Cell>{`${row.topic}/${row.id}`}</Cell>
            <Cell><UpdatedAt date={row.data?.last_seen} /></Cell>
            <Cell><Battery value={row.data?.battery} /></Cell>
            <Cell><Humidity value={row.data?.humidity} /></Cell>
            <Cell><Temperature value={row.data?.temperature} /></Cell>
            <Cell onClick={e => e.stopPropagation()}>
              <IconButton onClick={() => onUpdateAccessory({
                values: row,
              })}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Cell>
          </>
        )}
      />
      <UpdateForm {...updateFormProps} formComponent={TuyaTempSensorAccessoryForm} />
    </>
  );
};

export default TuyaTempSensorAccessoryTable;

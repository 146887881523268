import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Audiotrack, BluetoothDisabled, Search, Settings, ThumbUp, WarningRounded } from '@mui/icons-material';
import PlayerSubPage from './components/PlayerSubPage';
import SearchSubPage from './components/SearchSubPage';
import PageVisibility from './components/PageVisibility';
import LibrarySubPage from './components/LibrarySubPage';
import SettingsSubPage from './components/SettingsSubPage';
import { useConnection } from 'providers/ConnectionProvider';
import { useDataProvider } from 'providers';

const PlayerPage: FC = () => {
  const [page, setPage] = useState<'search' | 'player' | 'library' | 'settings'>('search');
  const connection = useConnection();
  const { playerData } = useDataProvider();

  const [showStatus, setShowStatus] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowStatus(true), 1000);
  }, []);

  return (
    <>
      <PageVisibility visible={page === 'search'}>
        <SearchSubPage />
      </PageVisibility>
      <PageVisibility visible={page === 'player'}>
        <PlayerSubPage />
      </PageVisibility>
      <PageVisibility visible={page === 'library'}>
        <LibrarySubPage isActive={page === 'library'} />
      </PageVisibility>
      <PageVisibility visible={page === 'settings'}>
        <SettingsSubPage />
      </PageVisibility>
      <BottomNavigation
        showLabels
        value={page}
        onChange={(event, newValue) => {
          setPage(newValue);
        }}
        sx={{ position: 'fixed', bottom: 0, width: '100%', pb: 3, backgroundColor: 'black', height: 80 }}
      >
        <BottomNavigationAction label="Search" value="search" icon={<Search />} />
        <BottomNavigationAction label="Player" value="player" icon={
          <>
            <Audiotrack />
            {showStatus && connection.status === 'direct' && !playerData.bluetooth?.connected && (
              <BluetoothDisabled sx={{ fontSize: 18, position: 'absolute', left: '53%', top: 4 }} color="warning" />
            )}
          </>
      } />
        <BottomNavigationAction label="Library" value="library" icon={<ThumbUp />} />
        <BottomNavigationAction label="Settings" value="settings" icon={(
          <>
            <Settings />
            {showStatus && connection.status !== 'direct' && (
              <WarningRounded sx={{ fontSize: 18, position: 'absolute', left: '53%', top: 4 }} color="warning" />
            )}
          </>
        )}
        />
      </BottomNavigation>
    </>
  );
};

export default PlayerPage;

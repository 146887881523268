import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import Table, { Cell, Header } from 'components/Table';
import { useDataProvider } from '../../../../providers';
import { AccessoryProps } from '../accessories.types';
import Temperature from 'components/Temperature';
import ParameterUpdateDialog, { Parameter } from '../../components/ParameterUpdateDialog';

const BoilerAccessoryTable: FC<AccessoryProps> = ({ accessories, data }) => {
  const { sendMqttMessage } = useDataProvider();
  const boilerConfig = accessories?.[0];
  const boilerData = data?.BOILER?.[0];
  const [editParameter, setEditParameter] = useState<Parameter | null>(null);

  const tableData = useMemo(
    () => boilerConfig ? [
      { id: 'topic', name: 'Topic', value: `${boilerConfig.topic}/${boilerConfig.id}` },
      { id: 'enable', name: 'State', value: boilerData?.value ? (boilerData.value.enable ? 'ON' : 'OFF') : '-', editable: !!boilerData?.value, type: 'boolean', rawValue: boilerData?.value.enable },
      ...(boilerData?.value ? [
        { id: 'minTemp', name: 'Min Temp', value: <Temperature value={boilerData.value.minTemp} />, editable: true, type: 'number', rawValue: boilerData.value.minTemp },
        { id: 'maxTemp', name: 'Max Temp', value: <Temperature value={boilerData.value.maxTemp} />, editable: true, type: 'number', rawValue: boilerData.value.maxTemp },
        { id: 'tempSensorTopic', name: 'Temp Sensor Topic', value: boilerData.value.tempSensorTopic, editable: true, type: 'string', rawValue: boilerData.value.tempSensorTopic },
        { id: 'relayTopic', name: 'Relay Topic', value: boilerData.value.relayTopic, editable: true, type: 'string', rawValue: boilerData.value.relayTopic },
      ] : []),
    ] : [],
    [boilerConfig, boilerData],
  );

  const saveParameter = useCallback((field: string, value: unknown) => {
    const newValue: Record<string, unknown> = { ...boilerData!.value };
    newValue[field] = value;
    sendMqttMessage(`${boilerConfig.topic}/${boilerConfig.id}/config`, JSON.stringify(newValue));
  }, [boilerData, boilerConfig, sendMqttMessage]);

  return (
    <>
      <Table
        grid="200px repeat(1, minmax(0, 1fr)) 60px"
        data={tableData}
        headers={<>
          <Header stiff>PARAMETER</Header>
          <Header resize>VALUE</Header>
          <Header />
        </>}
        row={({ row }) => (
          <>
            <Cell stiff>{row.name}</Cell>
            <Cell>{row.value}</Cell>
            <Cell onClick={e => e.stopPropagation()}>
              {row.editable
                ? <IconButton onClick={() => setEditParameter({
                  id: row.id,
                  name: row.name,
                  value: row.rawValue,
                  type: row.type as Parameter['type'],
                })}
                  >
                  <Edit fontSize="small" />
                </IconButton>
                : null}
            </Cell>
          </>
        )}
      />
      <ParameterUpdateDialog
        parameter={editParameter}
        onClose={() => setEditParameter(null)}
        onSave={saveParameter}
      />
    </>
  );
};

export default BoilerAccessoryTable;

import { createTheme, PaletteMode } from '@mui/material';

export const getTheme = (mode: PaletteMode) => createTheme({
  palette: {
    mode,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: mode === 'light' ? '#f7faff' : undefined,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          fontSize: '1.1rem',
          fontWeight: 'bold',
          color: '#2196f3',
        },
      },
    },
  },
});

import React from 'react';
import { BluetoothInfo, MediaItemDetail, MediaItemQueue, Status, StorageData } from '@smart-home/common';

export type DataContext = {
  data: StorageData | null;
  playerData: { status?: Status; source?: MediaItemDetail; queue?: MediaItemQueue; bluetooth?: BluetoothInfo };
  sendWsMessage: (topic: string, payload: unknown) => void;
  sendMqttMessage: (topic: string, payload: string) => void;
  subscribe: (topic: string, cb: (topic: string, payload: string) => void) => () => void;
};

const defaultDataProviderContext: DataContext = {
  data: null,
  playerData: {},
  sendWsMessage: () => null,
  sendMqttMessage: () => null,
  subscribe: () => () => null,
};

const DataProviderContext = React.createContext<DataContext>(defaultDataProviderContext);
DataProviderContext.displayName = 'DataProviderContext';

export default DataProviderContext;

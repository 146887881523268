import { Card, CardContent, Typography, Grid, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { FC, useCallback } from 'react';
import { useDataProvider } from 'providers';
import { useConnection } from 'providers/ConnectionProvider';

const HEATING = ['Lounge', 'Kitchen', 'Cabinet', 'Corridor'];
const RADIATORS = ['Boiler Room', 'Stockroom', 'Laundry', 'Bathroom', 'Bedroom', 'Stairs', 'Portal', 'Right Room', 'Left Room'];
const OTHER = ['Water', 'Recycling', 'Other', '3W open', '3W close'];

type ServoControlProps = {
  id: string;
  label: string;
  state: boolean;
  disabled: boolean;
  onToggle: (id: string) => void;
};

const ServoControl: FC<ServoControlProps> = ({ id, label, state, disabled, onToggle }) => (
  <FormControlLabel
    sx={{ mb: 1 }}
    control={
      <Switch
        checked={state}
        disabled={disabled}
        onChange={() => onToggle(id)}
      />
  } label={label}
  />
);

const ServoPage: FC = () => {
  const { status } = useConnection();
  const { data, sendMqttMessage } = useDataProvider();

  const getRelayValue = useCallback((id: string): boolean => {
    return (data?.SOLID_RELAY?.find(x => x.id === id)?.value as boolean) ?? false;
  }, [data?.SOLID_RELAY]);

  const onToggle = useCallback((id: string) => {
    const payload = data?.SOLID_RELAY?.find(x => x.id === id)?.value ? '0' : '1';
    sendMqttMessage(`h_relays/${id}/set`, payload);
  }, [data?.SOLID_RELAY, sendMqttMessage]);

  const isReadOnly = status !== 'direct';

  return (
    <Grid container px={2} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Servo data
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant='overline'>Warm floor</Typography>
                <FormGroup>
                  {HEATING.map((x, i) => (
                    <ServoControl
                      key={i}
                      id={i.toString()}
                      label={x}
                      disabled={isReadOnly}
                      state={!getRelayValue(i.toString())}
                      onToggle={onToggle}
                    />
                  ))}
                </FormGroup>
                <Typography variant='overline'>Other</Typography>
                <FormGroup>
                  {OTHER.map((x, i) => (
                    <ServoControl
                     key={i}
                     id={(i + 13).toString()}
                     label={x}
                     disabled={isReadOnly}
                     state={getRelayValue((i + 13).toString())}
                     onToggle={onToggle}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='overline'>Radiators</Typography>
                <FormGroup>
                  {RADIATORS.map((x, i) => (
                    <ServoControl
                      key={i}
                      id={(i + 4).toString()}
                      label={x}
                      disabled={isReadOnly}
                      state={!getRelayValue((i + 4).toString())}
                      onToggle={onToggle}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ServoPage;

import { Button, Checkbox, FormControlLabel, TextField, TextFieldProps, Grid, Link, Typography } from '@mui/material';
import { FC, ReactNode, useCallback } from 'react';
import { useForm, FieldErrors, Controller, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { settingSchema, SETTINGS_FIELDS } from './SettingsForm.config';
import { Stack } from '@mui/system';
import { useConnection } from 'providers/ConnectionProvider';
import ConnectionIndicator from 'pages/Root/components/ConnectionIndicator';

export type SettingsFormProps = {
  defaultValues?: SETTINGS_FIELDS;
  onSubmit: (values: SETTINGS_FIELDS) => void;
};

const muiErrors = <T extends FieldValues, >(errors: FieldErrors<T>, name: keyof T): Pick<TextFieldProps, 'error' | 'helperText'> => ({
  error: !!errors[name],
  helperText: errors[name]?.message as string,
});

const SettingsForm: FC<SettingsFormProps> = ({ onSubmit, defaultValues }) => {
  const { register, control, handleSubmit, formState: { errors }, watch } = useForm<SETTINGS_FIELDS>({
    defaultValues,
    resolver: yupResolver(settingSchema),
  });

  const connection = useConnection();

  const checkConnection = useCallback(() => {
    connection.checkConnection();
  }, [connection]);

  const [localApi, localApi2] = watch(['localApi', 'localApi2']);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Token"
            fullWidth
            error={!!errors.token}
            helperText={errors.token?.message}
            autoComplete="off"
            {...muiErrors(errors, 'token')}
            {...register('token')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Remote Api"
            fullWidth
            autoComplete="off"
            {...muiErrors(errors, 'remoteApi')}
            {...register('remoteApi')}
          />
          <FormControlLabel
            label="Enabled"
            sx={{ ml: 0 }}
            control={
              <Controller
                name="enableRemoteApi"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Pool Interval (sec)"
            fullWidth
            type="number"
            autoComplete="off"
            {...muiErrors(errors, 'poolInterval')}
            {...register('poolInterval')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Local Api"
            fullWidth
            autoComplete="off"
            {...muiErrors(errors, 'localApi')}
            {...register('localApi')}
          />
          {localApi.startsWith('https://') && (
            <Link
            href={`${localApi}/health`}
            target="_blank"
            sx={{ float: 'right' }}
            >
              check certificate
            </Link>
          )}
          <FormControlLabel
            label="Enabled"
            sx={{ ml: 0, mb: 1 }}
            control={
              <Controller
                name="enableLocalApi"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
          />
          <TextField
            label="Local Api 2"
            fullWidth
            autoComplete="off"
            {...muiErrors(errors, 'localApi2')}
            {...register('localApi2')}
          />
          {localApi.startsWith('https://') && (
            <Link
            href={`${localApi2}/health`}
            target="_blank"
            sx={{ float: 'right' }}
            >
              check certificate
            </Link>
          )}
          <FormControlLabel
            label="Enabled"
            sx={{ ml: 0 }}
            control={
              <Controller
                name="enableLocalApi2"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Button type="submit" variant="contained" fullWidth>
              Save
            </Button>
            <Button variant="outlined" fullWidth onClick={checkConnection}>
              {!connection.connectInProgress && <ConnectionIndicator />}
              <Typography ml={1} variant="button">
                {connection.connectInProgress ? 'Updating...' : connection.status === 'direct' ? 'Connected' : 'Refresh'}
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default SettingsForm;

import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Accessory } from '@smart-home/common';
import axios, { AxiosError } from 'axios';
import { FC, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ConfirmDialogComponentProps, useConfirm } from '../../../hooks/useConfirm';
import { ElementWithProps } from '../../../types';
import { useConnection } from 'providers/ConnectionProvider';

export type DeleteComponentProps = {
  onConfirm: () => void;
  confirmation: ElementWithProps<ConfirmDialogComponentProps>;
};

const DeleteComponent: FC<DeleteComponentProps> = ({ onConfirm, confirmation: [Confirmation, confirmationProps] }) => (
  <>
    <Button startIcon={<Delete />} color="error" onClick={onConfirm}>Delete</Button>
    <Confirmation title="Are you sure you want to delete it?" {...confirmationProps} />
  </>
);

export const useDeleteAccessory = (accessoryKey?: string, onDeleted?: () => void): [ElementWithProps<DeleteComponentProps>, (accessoryKey: string) => Promise<void>] => {
  const [Confirmation, showConfirm] = useConfirm();
  const { apiUrl } = useConnection();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteSensor } = useMutation<unknown, AxiosError, string>(async (key) =>
    axios.delete(`${apiUrl}/accessories/${key}`), {
    onSuccess: (_, key) => {
      const accessories = queryClient.getQueryData<Accessory[]>('accessories') ?? [];
      queryClient.setQueryData('accessories', accessories.filter(x => x.key !== key));
      onDeleted?.();
    },
  });

  const onDelete = useCallback(async (key: string) => {
    showConfirm(() => {
      deleteSensor(key);
    });
  }, [deleteSensor, showConfirm]);

  const onConfirm = useCallback(() => {
    accessoryKey && onDelete(accessoryKey);
  }, [onDelete, accessoryKey]);

  return [[DeleteComponent, { onConfirm, confirmation: Confirmation }], onDelete];
};

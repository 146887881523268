import { Grid, TextField, TextFieldProps } from '@mui/material';
import { AccessoryType } from '@smart-home/common';
import { FC } from 'react';
import { FieldErrors, UseFormReturn } from 'react-hook-form';

type SonoffSwitchAccessoryProps = UseFormReturn<SonoffSwitchAccessoryFormModel>;

const muiErrors = <T, >(errors: FieldErrors<T>, name: keyof T): Pick<TextFieldProps, 'error' | 'helperText'> => ({
  error: !!errors[name],
  helperText: errors[name]?.message as string,
});

export type SonoffSwitchAccessoryFormModel = {
  alias: string;
  topic: string;
  cmdTopic: string;
  id: string;
  type: AccessoryType;
};

const SonoffSwitchAccessoryForm: FC<SonoffSwitchAccessoryProps> = (props) => {
  const { register, formState: { errors } } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Alias"
          fullWidth
          error={!!errors.alias}
          helperText={errors.alias?.message}
          autoComplete="off"
          {...muiErrors(errors, 'alias')}
          {...register('alias')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="State Topic"
          fullWidth
          disabled
          error={!!errors.topic}
          helperText={errors.topic?.message}
          autoComplete="off"
          {...muiErrors(errors, 'topic')}
          {...register('topic')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Cmd Topic"
          fullWidth
          disabled
          error={!!errors.cmdTopic}
          helperText={errors.cmdTopic?.message}
          autoComplete="off"
          {...muiErrors(errors, 'cmdTopic')}
          {...register('cmdTopic')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Id"
          fullWidth
          error={!!errors.id}
          helperText={errors.id?.message}
          autoComplete="off"
          {...muiErrors(errors, 'id')}
          {...register('id')}
        />
      </Grid>
    </Grid>
  );
};

export default SonoffSwitchAccessoryForm;

import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, BoxProps } from '@mui/material';

type SpinnerProps = {
  active?: boolean;
} & BoxProps;

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    position: 'relative',
    marginTop: 26,
  },
  root: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: 50,
    width: 50,
    margin: '-25px 0 0 -25px',
    '& span': {
      position: 'absolute',
      left: 10,
      width: 0,
      height: 0,
    },
    '& span:nth-child(1)': {
      borderTop: '30px solid #fff',
      borderLeft: '30px solid transparent',
      animation: '$slice1 2s infinite ease-out',
    },
    '& span:nth-child(2)': {
      borderBottom: '30px solid #fff',
      borderRight: '30px solid transparent',
      animation: '$slice2 2s infinite ease-out',
    },
  },
  '@keyframes slice1': {
    '0%,75%,100%': {
      transform: 'translateX(0px)',
    },
    '25%': {
      transform: 'translateX(10px)',
    },
    '50%': {
      transform: 'translateY(-12px)',
    },
  },
  '@keyframes slice2': {
    '0%,100%': {
      transform: 'translateX(0px)',
    },
    '25%': {
      transform: 'translateX(-10px)',
    },
    '50%': {
      transform: 'translateY(12px)',
    },
  },
}), { name: 'Spinner' });

const Spinner: FC<SpinnerProps> = ({ active = true, ...props }) => {
  const classNames = useStyles();
  if (!active) return null;

  return (
    <Box {...props} className={classNames.wrapper}>
      <Box className={classNames.root}>
        <span />
        <span />
      </Box>
    </Box>
  );
};

export default Spinner;

import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Accessory, Camera, CAMERA, CameraValue } from '@smart-home/common';
import { FC, useMemo } from 'react';
import Table, { Cell, Header } from 'components/Table';
import { useUpdateAccessory } from '../../hooks/useUpdateAccessory';
import { AccessoryProps } from '../accessories.types';
import { getValue } from '../accessories.utils';
import SurveillanceAccessoryForm from './SurveillanceAccessory.form';

const SurveillanceAccessoryTable: FC<AccessoryProps> = ({ accessories, data }) => {
  const [[UpdateForm, updateFormProps], onUpdateAccessory] = useUpdateAccessory<Accessory>();
  const rows = useMemo(() => (accessories as Camera[]).map(x => ({
    ...x,
    data: getValue<CameraValue>(CAMERA, x.id, data),
  })), [accessories, data]);

  return (
    <>
      <Table
        grid="100px repeat(2, minmax(0, 1fr)) 60px"
        data={rows}
        headers={<>
          <Header stiff>ALIAS</Header>
          <Header resize>TOPIC</Header>
          <Header>URI</Header>
          <Header />
        </>}
        row={({ row }) => (
          <>
            <Cell stiff>{row.alias}</Cell>
            <Cell>{`${row.topic}/${row.id}`}</Cell>
            <Cell>{`${row.host}:${row.port}`}</Cell>
            <Cell onClick={e => e.stopPropagation()}>
              <IconButton onClick={() => onUpdateAccessory({
                values: row,
              })}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Cell>
          </>
        )}
      />
      <UpdateForm {...updateFormProps} formComponent={SurveillanceAccessoryForm} />
    </>
  );
};

export default SurveillanceAccessoryTable;

import { FC, PropsWithChildren, useCallback, useState } from 'react';
import SettingsContext, { defaultSettingsContext, Settings } from './SettingsContext';

const getCurrentSettings = () => {
  const settingsStr = localStorage.getItem('settings');
  let newSettings: Settings['settings'] = { ...defaultSettingsContext.settings };
  if (settingsStr) {
    try {
      newSettings = JSON.parse(settingsStr);
    } catch {}
  }
  return newSettings;
};

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [settings, setSettings] = useState<Settings['settings']>(getCurrentSettings());

  const updateSettings = useCallback((newSettings: Settings['settings']) => {
    const currentSettingsStr = JSON.stringify(getCurrentSettings());
    const newSettingsStr = JSON.stringify(newSettings);

    setSettings(newSettings);
    if (currentSettingsStr !== newSettingsStr) {
      localStorage.setItem('settings', JSON.stringify(newSettings));
      return true;
    }
    return false;
  }, []);

  return (
    <SettingsContext.Provider value={{ settings, setSettings: updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

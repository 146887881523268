import { useRef, FC, useEffect, useImperativeHandle, MutableRefObject } from 'react';
import io, { Socket } from 'socket.io-client';
import { StorageData, getStorageDataInitValue, AccessoryKeys, AccessoryValue, AccessoryDetailValue } from '@smart-home/common';
import { useSettings } from '../SettingsProvider';
import { useConnection } from '../ConnectionProvider';
import { useDataProvider } from './useDataProvider';

type WsSourceProps = {
  sourceRef?: MutableRefObject<{
    sendMqttMessage: (topic: string, payload: string) => void;
    sendWsMessage: (topic: string, payload: unknown) => void;
    subscribe: (topic: string) => void;
    unsubscribe: (topic: string) => void;
  } | undefined >;
  setData: (data: StorageData) => void;
  setPlayerData: (topic: string, data: unknown) => void;
  onMessage: (topic: string, payload: string) => void;
};

const WsSource: FC<WsSourceProps> = ({ setData, setPlayerData, sourceRef, onMessage }) => {
  const { data } = useDataProvider();
  const { settings: { token } } = useSettings();
  const { setWsStatus, apiUrl } = useConnection();
  const ref = useRef({ setWsStatus, setPlayerData, setData, onMessage });
  const socketRef = useRef<Socket>();
  const dataRef = useRef(data);
  dataRef.current = data;

  useImperativeHandle(sourceRef, () => ({
    sendMqttMessage: (topic, payload) => {
      console.log(`[mqtt] ${topic} - \`${payload}\``);
      socketRef.current?.emit('mqtt', topic, payload);
    },
    sendWsMessage: (topic, payload) => {
      console.log(`[ws] ${topic}`, payload);
      socketRef.current?.emit(topic, payload);
    },
    subscribe: (topic) => {
      socketRef.current?.emit('subscribe', topic);
    },
    unsubscribe: (topic) => {
      socketRef.current?.emit('unsubscribe', topic);
    },
  }));

  useEffect(() => {
    if (!token || !apiUrl) return;

    const wsPath = new URL(apiUrl).origin;

    const socket = io(`${wsPath}/ws`, {
      path: '/socket.io/ws/',
      extraHeaders: {
        Authorization: token,
      },
    });

    socketRef.current = socket;

    socket.on('connect', () => {
      ref.current.setWsStatus(true);
      socket.emit('storage/get');
    });

    socket.on('disconnect', () => {
      ref.current.setWsStatus(false);
    });

    socket.on('storage/patch', (msg: StorageData) => {
      const data = dataRef.current;

      const updatedData: StorageData = {
        ...getStorageDataInitValue(),
        ...data,
        ...msg,
        metadata: {
          ...data?.metadata,
          ...msg.metadata,
        } as any,
      };

      type AccVal = AccessoryDetailValue<AccessoryValue>[];

      AccessoryKeys.forEach(key => {
        if (!data?.[key]) return;
        if (Array.isArray(data[key])) {
          (data[key] as AccVal).forEach((x) => {
            if (!(updatedData[key] as AccVal)?.find(({ id }) => id === x.id)) {
              (updatedData[key] as AccVal).push(x);
            }
          });
        } else if (data[key] && typeof data[key] === 'object') {
          updatedData[key] = {
            ...data[key],
            ...updatedData[key],
          } as any;
        }
      });

      ref.current.setData(updatedData);
    });

    socket.on('mqtt', (topic: string, payload: string) => {
      ref.current.onMessage(topic, payload);
    });

    socket.on('storage/post', (msg: StorageData) => {
      ref.current.setData(msg);
    });

    socket.on('player/status', (msg: unknown) => {
      ref.current.setPlayerData('status', msg);
    });

    socket.on('player/source', (msg: unknown) => {
      ref.current.setPlayerData('source', msg);
    });

    socket.on('player/queue', (msg: unknown) => {
      ref.current.setPlayerData('queue', msg);
    });

    socket.on('bluetooth/status', (msg: unknown) => {
      ref.current.setPlayerData('bluetooth', msg);
    });

    return () => {
      socket.close();
    };
  }, [token, apiUrl]);

  return null;
};

export default WsSource;

import { TuyaTempSensorValue } from './accessories/tuya-temp-sensor';
import { TempSensorValue, UpsStatusValue } from './accessories';
import { SolidRelayValue } from './accessories/solid-relay';
import { SonoffSwitchValue } from './accessories/sonoff-switch';
import { AccessoryDetailValue, BOILER, CAMERA, EMS, SOLID_RELAY, SONOFF_SWITCH, TEMP_SENSOR, TUYA_TEMP_SENSOR, UPS, XIAOMI_TEMP_SENSOR } from './accessories/types';
import { XiaomiTempSensorValue } from './accessories/xiaomi-temp-sensor';
import { EmsBoilerValue } from './accessories/ems-boiler';
import { BoilerValue } from './accessories/boiler';
import { CameraValue } from './accessories/camera';

export type BoilerStatistic = {
  data: {
    date: string;
    isActive: boolean;
    heatworkmin: number;
  }[];
};

export type Metadata = { lastUpdate: string | null; boilerStatistic?: BoilerStatistic };

export type StorageData = {
  [SOLID_RELAY]: AccessoryDetailValue<SolidRelayValue>[];
  [TEMP_SENSOR]: AccessoryDetailValue<TempSensorValue>[];
  [XIAOMI_TEMP_SENSOR]: AccessoryDetailValue<XiaomiTempSensorValue>[];
  [SONOFF_SWITCH]: AccessoryDetailValue<SonoffSwitchValue>[];
  [TUYA_TEMP_SENSOR]: AccessoryDetailValue<TuyaTempSensorValue>[];
  [BOILER]: AccessoryDetailValue<BoilerValue>[];
  [EMS]: Partial<EmsBoilerValue>;
  [UPS]: AccessoryDetailValue<UpsStatusValue>[];
  [CAMERA] : AccessoryDetailValue<CameraValue>[];
  metadata: Metadata | null;
};

export const getStorageDataInitValue = (): StorageData => ({
  [SOLID_RELAY]: [] as AccessoryDetailValue<SolidRelayValue>[],
  [TEMP_SENSOR]: [] as AccessoryDetailValue<TempSensorValue>[],
  [XIAOMI_TEMP_SENSOR]: [] as AccessoryDetailValue<XiaomiTempSensorValue>[],
  [SONOFF_SWITCH]: [] as AccessoryDetailValue<SonoffSwitchValue>[],
  [TUYA_TEMP_SENSOR]: [] as AccessoryDetailValue<TuyaTempSensorValue>[],
  [BOILER]: [] as AccessoryDetailValue<BoilerValue>[],
  [EMS]: {},
  [UPS]: [] as AccessoryDetailValue<UpsStatusValue>[],
  [CAMERA]: [] as AccessoryDetailValue<CameraValue>[],
  metadata: null,
});

export type DevicesStatePatch = Partial<StorageData>;

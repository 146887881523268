import { Accessory } from '@smart-home/common';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { DialogComponentProps, useFormDialog } from '../../../hooks/useFormDialog';
import { ElementWithProps } from '../../../types';
import { DeleteComponentProps, useDeleteAccessory } from './useDeleteAccessory';
import { useConnection } from 'providers/ConnectionProvider';

type Key = { key?: string };

type UpdateAccessoryOptions<TModel extends Key> = {
  values: TModel;
};

// const getPatch = <TModel extends object>(src: TModel, current: TModel): Partial<TModel> => {
//   const result: Partial<Record<keyof TModel, unknown>> = {};
//   Object.keys(current).map(x => x as keyof TModel).forEach(key => {
//     if (src[key] !== current[key]) {
//       result[key] = current[key];
//     }
//   });
//   return result as TModel;
// };

export const useUpdateAccessory = <TModel extends Key>(): [ElementWithProps<DialogComponentProps<TModel, DeleteComponentProps>>, (options: UpdateAccessoryOptions<TModel>) => void] => {
  const { apiUrl } = useConnection();
  const queryClient = useQueryClient();
  const [options, setOptions] = useState<UpdateAccessoryOptions<TModel>>();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: addSensor } = useMutation<Accessory, AxiosError<{message: string}>, TModel>(async (values) =>
    axios.post<Accessory>(`${apiUrl}/accessories/${values.key}`, values).then(x => x.data), {
    onSuccess: (accessory) => {
      queryClient.setQueryData<Accessory[] | undefined>('accessories', data => {
        if (!data) return data;
        const accessories = [...data];
        const index = accessories.findIndex(x => x.key === accessory.key);
        accessories[index] = accessory;
        return accessories;
      });
    },
    onError: (error) => {
      const msg = error.response?.data?.message;
      if (msg && msg.includes('E11000')) {
        enqueueSnackbar('Accessory with same options is already existed', { variant: 'error' });
        return;
      }
      enqueueSnackbar('Unknown error', { variant: 'error' });
    },
  });

  const [[UpdateForm, updateFormProps], showUpdateForm, { onClose: closeUpdateForm }] = useFormDialog<TModel, DeleteComponentProps>({}, addSensor);
  const [DeleteComponentWithProps] = useDeleteAccessory(options?.values.key, () => closeUpdateForm());

  const onUpdate = useCallback((options: UpdateAccessoryOptions<TModel>) => {
    setOptions(options);
    showUpdateForm(options.values);
  }, [showUpdateForm]);

  const updateModalProps = useMemo<DialogComponentProps<TModel, DeleteComponentProps>>(() => ({
    ...updateFormProps,
    footer: DeleteComponentWithProps,
    title: 'Update Sensor',
    maxWidth: 'sm',
    fullWidth: true,
  }), [DeleteComponentWithProps, updateFormProps]);

  return [[UpdateForm, updateModalProps], onUpdate];
};

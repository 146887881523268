import { Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';

interface TemperatureProps {
  value?: number;
}

const Temperature: FC<TemperatureProps> = ({ value }) => {
  const [temp, setTemp] = useState<number | null>(null);
  const lastUpdate = useRef(0);
  const [direction, setDirection] = useState<'up' | 'down' | 'none'>('none');

  useEffect(() => {
    if (typeof value !== 'undefined' && value !== temp) {
      if (temp !== null) {
        setDirection(value > temp ? 'up' : 'down');
        lastUpdate.current = new Date().getTime();
      }
      setTemp(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (lastUpdate.current && new Date().getTime() - lastUpdate.current > 10_000) {
        setDirection('none');
        lastUpdate.current = 0;
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const color = direction === 'up' ? '#d32f2f' : (direction === 'down' ? '#1976d2' : undefined);

  return (
    <Typography color={color} whiteSpace="nowrap" component="span" fontWeight="medium">
      {temp}&deg;
      {direction === 'up' && ' ↑'}
      {direction === 'down' && ' ↓'}
    </Typography>
  );
};

export default Temperature;

import { FC } from 'react';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Accessory } from '@smart-home/common';
import { SonoffSwitch } from '@smart-home/common/src/accessories/sonoff-switch';
import Table, { Cell, Header } from 'components/Table';
import { useUpdateAccessory } from '../../hooks/useUpdateAccessory';
import { AccessoryProps } from '../accessories.types';
import SonoffSwitchAccessoryForm from './SonoffSwitchAccessory.form';

const SonoffSwitchAccessoryTable: FC<AccessoryProps<SonoffSwitch>> = ({ accessories, data }) => {
  const [[UpdateForm, updateFormProps], onUpdateAccessory] = useUpdateAccessory<Accessory>();

  return (
    <>
      <Table
        grid="100px repeat(1, minmax(0, 1fr)) repeat(1, minmax(0, 1fr)) 80px 60px"
        data={accessories}
        headers={<>
          <Header stiff>ALIAS</Header>
          <Header resize>STATE TOPIC</Header>
          <Header resize>CMD TOPIC</Header>
          <Header resize>STATE</Header>
          <Header />
        </>}
        row={({ row }) => (
          <>
            <Cell stiff>{row.alias}</Cell>
            <Cell>{`${row.topic}/${row.id}`}</Cell>
            <Cell>{`${row.cmdTopic}/${row.id}`}</Cell>
            <Cell>???</Cell>
            <Cell onClick={e => e.stopPropagation()}>
              <IconButton onClick={() => onUpdateAccessory({
                values: row,
              })}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Cell>
          </>
        )}
      />
      <UpdateForm {...updateFormProps} formComponent={SonoffSwitchAccessoryForm} />
    </>
  );
};

export default SonoffSwitchAccessoryTable;

import { object, ObjectSchema, string } from 'yup';

export const TEMP_SENSOR = 'TEMP_SENSOR' as const;
export const XIAOMI_TEMP_SENSOR = 'XIAOMI_TEMP_SENSOR' as const;
export const TUYA_TEMP_SENSOR = 'TUYA_TEMP_SENSOR' as const;
export const SONOFF_SWITCH = 'SONOFF_SWITCH' as const;
export const SOLID_RELAY = 'SOLID_RELAY' as const;
export const EMS = 'EMS' as const;
export const BOILER = 'BOILER' as const;
export const CAMERA = 'CAMERA' as const;
export const UPS = 'UPS' as const;

export type AccessoryType = typeof TEMP_SENSOR | typeof SOLID_RELAY | typeof XIAOMI_TEMP_SENSOR | typeof SONOFF_SWITCH | typeof TUYA_TEMP_SENSOR | typeof EMS | typeof BOILER | typeof UPS | typeof CAMERA;
export const AccessoryKeys: AccessoryType[] = [TEMP_SENSOR, XIAOMI_TEMP_SENSOR, SOLID_RELAY, SONOFF_SWITCH, TUYA_TEMP_SENSOR, EMS, BOILER, UPS, CAMERA];

export type Accessory = {
  key?: string;
  type: AccessoryType;
  alias: string;
  topic: string;
  id: string;
  description?: string;
  host?: string;
  port?: string;
};

export type AccessoryValue = string | number | boolean | object;

export type Message = { topic: string; payload: string };

export type AccessoryModule<TValue> = {
  type: AccessoryType;
  parse: (msg: Message) => TValue;
  topicsToBroadcast: string[];
};

export type AccessoryDetailValue<TValue extends AccessoryValue> = {
  id: string;
  value: TValue;
  updatedAt?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const accessorySchema: ObjectSchema<{}> = object<Record<keyof Accessory, any>>({
  key: string()
    .notRequired(),
  type: string()
    .oneOf(AccessoryKeys)
    .required(),
  alias: string()
    .required(),
  topic: string()
    .required(),
  id: string()
    .required(),
  description: string()
    .nullable()
    .notRequired()
    .trim(),
  port: string()
    .trim()
    .notRequired(),
  host: string()
    .matches(/^192\.168\.[0-9]+\.[0-9]+$/)
    .trim()
    .notRequired(),
});

import { StorageData } from '@smart-home/common';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useConnection } from '../ConnectionProvider';
import DataProviderContext from './DataProviderContext';
import RestSource from './RestSource';
import WsSource from './WsSource';

export type DataProviderProps = {
  children: JSX.Element | JSX.Element[];
};

type MqttMessageHandler = (topic: string, payload: string) => void;

export const DataProvider: FC<DataProviderProps> = ({ children }) => {
  const { status } = useConnection();
  const [data, setData] = useState<StorageData | null>(null);
  const [player, setPlayer] = useState<Record<string, unknown>>({});
  const sourceRef = useRef<{
    sendMqttMessage:(topic: string, payload: string) => void;
    sendWsMessage:(topic: string, payload: unknown) => void;
    subscribe: (topic: string) => void;
    unsubscribe: (topic: string) => void;
  }>();
  const subscribers = useRef<Record<string, MqttMessageHandler>>({});

  const playerData2 = useRef<Record<string, unknown>>();
  playerData2.current = player;

  const setPlayerData = useCallback((topic: string, data: unknown) => {
    playerData2.current = {
      ...playerData2.current,
      [topic]: data,
    };
    setPlayer(playerData2.current);
  }, []);

  useEffect(() => {
    if (status === 'offline') {
      setData(null);
      return;
    }
  }, [status]);

  const sendMqttMessage = useCallback((topic: string, payload: string) => {
    sourceRef.current?.sendMqttMessage(topic, payload);
  }, []);

  const sendWsMessage = useCallback((topic: string, payload: unknown) => {
    sourceRef.current?.sendWsMessage(topic, payload);
  }, []);

  const subscribe = useCallback((topic: string, cb: (topic: string, payload: string) => void) => {
    subscribers.current[topic] = cb;
    sourceRef.current?.subscribe(topic);
    return () => {
      delete subscribers.current[topic];
      sourceRef.current?.unsubscribe(topic);
    };
  }, []);

  const onMessage = useCallback((topic: string, payload: string) => {
    if (subscribers.current[topic]) {
      subscribers.current[topic](topic, payload);
    }
  }, []);

  const Source = status === 'direct' ? WsSource : (status === 'online' ? RestSource : null);

  return (
    <DataProviderContext.Provider value={{ data, sendMqttMessage, sendWsMessage, subscribe, playerData: player }}>
      {Source && <Source setData={setData} setPlayerData={setPlayerData} sourceRef={sourceRef} onMessage={onMessage} />}
      {children}
    </DataProviderContext.Provider>
  );
};

import { Card, CardContent, Typography, Grid, Stack, IconButton, Box, Slider } from '@mui/material';
import { MediaItem, PlayerCommand } from '@smart-home/common';
import { FC, useCallback, useState } from 'react';
import { useDataProvider } from 'providers';
import { Battery0Bar, Battery1Bar, Battery2Bar, Battery3Bar, Battery4Bar, Battery5Bar, Battery6Bar, BatteryFull, BluetoothAudio,
  BluetoothDisabled, Favorite, FavoriteBorder, SkipNextRounded, SkipPreviousRounded } from '@mui/icons-material';
import dayjs from 'dayjs';
import { addVideo, isFavorite, removeVideo } from './LibrarySubPage';
import { styled } from '@mui/material/styles';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';

type PlayerSubPageProps = {
};

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const PlayerSubPage: FC<PlayerSubPageProps> = () => {
  const { playerData, sendWsMessage } = useDataProvider();

  const { status, source, queue, bluetooth } = playerData;

  const sendPlayerCommand = useCallback((data: PlayerCommand) => {
    sendWsMessage('player/command', data);
  }, [sendWsMessage]);

  const onPause = useCallback(() => {
    sendPlayerCommand({ type: 'pause' });
  }, [sendPlayerCommand]);

  const onPlay = useCallback(() => {
    sendPlayerCommand({ type: 'play' });
  }, [sendPlayerCommand]);

  const onVolume = useCallback((value: number) => {
    if (value !== playerData.status!.volume) {
      sendPlayerCommand({ type: 'volume', value });
    }
  }, [sendPlayerCommand, playerData.status]);

  const time = status ? (dayjs.duration(status.time, 'seconds').format('HH:mm:ss')).replace(/^00\:/, '') : '-:-';
  const durationTxt = status?.length && (status?.length ?? 0) > 10 ? (dayjs.duration(status.length - status?.time, 'seconds').format('HH:mm:ss')).replace(/^00\:/, '') : '';
  const isFavoriteVideo = isFavorite(source?.id);
  const [, forceUpdate] = useState({});

  const onFavoriteToggle = useCallback(() => {
    if (isFavoriteVideo) {
      removeVideo(source!.id);
    } else {
      const { related, streamUrl, ...media } = source!;
      addVideo(media);
    }
    forceUpdate({});
  }, [isFavoriteVideo, source]);

  const next: MediaItem[] | undefined = queue?.[0] === 'auto'
    ? source?.related
    : queue?.filter(x => x !== 'auto') as MediaItem[];

  const paused = status?.state !== 'playing';

  const bluetoothBattery = bluetooth?.battery;

  return (
    <Grid container px={0} spacing={1}>
      {/* {source && (
        <Box style={{
          backgroundImage: `url(${source.thumbnail})`,
          backgroundRepeat: 'no-repeat',
          position: 'fixed',
          filter: 'blur(10px)',
          opacity: 0.2,
          left: 0,
          top: 0,
          height: '100vh',
          width: '100vw',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        />
      )} */}
      <Grid item xs={12} style={{ zIndex: 10 }}>
        <Card sx={{ backgroundColor: 'transparent' }}>
          <CardContent sx={{ px: 1, py: 0 }}>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                {source && (
                  <img src={source.thumbnail} width="100%" />
                )}
                <Box mb={1}>
                  <Typography variant="body2" component="p" fontSize={16} lineHeight={1.1} letterSpacing={1}>
                    {source?.title ?? '<no-track>'}
                  </Typography>
                  {/* <Typography variant="caption">{'11m'} • {'03:03'}</Typography> */}
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Slider
                  aria-label="time-indicator"
                  size="small"
                  value={status?.time ?? 0}
                  disableSwap
                  min={0}
                  step={1}
                  max={(!status?.length || status.length === -1) ? 100 : status.length}
                  onChange={(_, value) => null}
                  sx={{
                    color: '#fff',
                    height: 4,
                    '& .MuiSlider-thumb': {
                      width: 8,
                      height: 8,
                      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                      '&:before': {
                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                      },
                      '&:hover, &.Mui-focusVisible': {
                        boxShadow: '0px 0px 0px 8px rgb(255 255 255 / 16%)',
                      },
                      '&.Mui-active': {
                        width: 20,
                        height: 20,
                      },
                    },
                    '& .MuiSlider-rail': {
                      opacity: 0.28,
                    },
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: -3,
                  }}
                >
                  <TinyText>{time}</TinyText>
                  {durationTxt && <TinyText>-{durationTxt}</TinyText>}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 0,
                    mb: 1,
                  }}
                >
                  <Box width={70} pl={1}>
                    {bluetooth?.connected
                      ? (
                        <>
                          <BluetoothAudio color="info" />
                          {typeof bluetoothBattery === 'number' && (
                            <>
                              {bluetoothBattery <= 10 && <Battery0Bar color="error" />}
                              {bluetoothBattery > 10 && bluetoothBattery <= 30 && <Battery1Bar color="warning" />}
                              {bluetoothBattery > 30 && bluetoothBattery <= 40 && <Battery2Bar />}
                              {bluetoothBattery > 40 && bluetoothBattery <= 50 && <Battery3Bar />}
                              {bluetoothBattery > 50 && bluetoothBattery <= 60 && <Battery4Bar />}
                              {bluetoothBattery > 60 && bluetoothBattery <= 70 && <Battery5Bar />}
                              {bluetoothBattery > 70 && bluetoothBattery <= 80 && <Battery6Bar />}
                              {bluetoothBattery > 80 && <BatteryFull />}
                            </>
                          )}
                        </>
                      ) : (
                        <BluetoothDisabled />
                      )}
                  </Box>
                  <Box>
                    <IconButton aria-label="next song" disabled>
                      <SkipPreviousRounded fontSize="large" htmlColor={'#fff'} />
                    </IconButton>
                    <IconButton
                      aria-label={paused ? 'play' : 'pause'}
                      onClick={status?.state === 'playing' ? onPause : onPlay}
                    >
                      {paused ? (
                        <PlayArrowRounded
                        sx={{ fontSize: '3rem' }}
                        htmlColor={'#fff'}
                        />
                      ) : (
                        <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={'#fff'} />
                      )}
                    </IconButton>
                    <IconButton aria-label="next song" onClick={() => sendPlayerCommand({ type: 'play', media: next![0], next: next!.slice(1) })} disabled={!next?.length}>
                      <SkipNextRounded fontSize="large" htmlColor={'#fff'} />
                    </IconButton>
                  </Box>
                  <Box width={70} sx={{ textAlign: 'right' }}>
                    <IconButton onClick={onFavoriteToggle} disabled={!source}>
                      {isFavoriteVideo ? <Favorite color="error" /> : <FavoriteBorder />}
                    </IconButton>
                  </Box>
                </Box>
                <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">
                  <VolumeDownRounded htmlColor={'rgba(255,255,255,0.4)'} />
                  <Slider
                    min={0}
                    max={320}
                    value={Number(playerData.status?.volume ?? 0)}
                    onChange={(_, v) => onVolume(v as number)}
                    sx={{
                      color: '#fff',
                      '& .MuiSlider-track': {
                        border: 'none',
                      },
                      '& .MuiSlider-thumb': {
                        width: 24,
                        height: 24,
                        backgroundColor: '#fff',
                        '&:before': {
                          boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                        },
                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                          boxShadow: 'none',
                        },
                      },
                    }}
                  />
                  <VolumeUpRounded htmlColor={'rgba(255,255,255,0.4)'} />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {!!next?.length && (
          <Box pl={8}>
            <Typography variant="overline" component="p">Next to play...</Typography>
            {next.slice(0, 2).map(x => (
              <Box key={x.id} display="inline-block" width={140} style={{ verticalAlign: 'top', cursor: 'pointer' }} onClick={() => sendPlayerCommand({ type: 'play', media: x })}>
                <img src={x.thumbnail} width="100%" style={{ verticalAlign: 'top' }} />
                <Typography variant="body2" fontSize={12}>{x.title?.substring(0, 50)}...</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default PlayerSubPage;

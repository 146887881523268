import PlayerPage from 'pages/PlayerPage';
import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';

export const router = createBrowserRouter([
  {
    path: '*',
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
  },
]);

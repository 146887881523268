import { FC, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { useSettings } from 'providers/SettingsProvider';
import { StorageData } from '@smart-home/common';

const WsTestPage: FC = () => {
  const { settings } = useSettings();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!settings.token) return;

    const socket = io('http://localhost:8080/ws', {
      path: '/socket.io/ws/',
      extraHeaders: {
        Authorization: settings.token,
      },
    });

    socket.on('connect', () => {
      console.log('connected to server');
    });

    // handle the event sent with socket.send()
    socket.on('disconnect', data => {
      console.log('disconnected');
    });

    socket.on('storage', (msg: StorageData) => {
      socket.emit('command', 'test');
    });
  }, []);

  return (
    <Box>
      123
    </Box>
  );
};

export default WsTestPage;

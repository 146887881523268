import { StorageData } from '@smart-home/common';
import { FC, MutableRefObject, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useSettings } from '../SettingsProvider';

type RestSourceProps = {
  sourceRef?: MutableRefObject<{
    sendMqttMessage: (topic: string, payload: string) => void;
    subscribe: (topic: string) => void;
    unsubscribe: (topic: string) => void;
  } | undefined >;
  setData: (data: StorageData) => void;
  onMessage: (topic: string, payload: string) => void;
};

const getData = async (cloudflareUrl: string, cloudflareAuth: string) => {
  const { data } = await axios.get<StorageData>(
    `${cloudflareUrl}/storage`,
    { headers: { Authorization: cloudflareAuth } },
  );

  return data;
};

const RestSource: FC<RestSourceProps> = ({ setData }) => {
  const { settings: { token, remoteApi, poolInterval } } = useSettings();

  const fetchData = useCallback(async () => {
    if (!remoteApi || !token) return;
    setData(await getData(remoteApi, token));
  }, [remoteApi, token, setData]);

  useEffect(() => {
    fetchData();
    const timer = setInterval(fetchData, poolInterval);
    return () => clearInterval(timer);
  }, [fetchData, poolInterval]);

  return null;
};

export default RestSource;

import { FC } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { useDataProvider } from '../../../../providers';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

const DataStateIndicator: FC = () => {
  const { data } = useDataProvider();

  const lastUpdate = data?.metadata?.lastUpdate;
  const showWarning = !lastUpdate || dayjs(new Date()).diff(lastUpdate, 'minutes') > 10;

  return (
    <>
      {showWarning && data && (
        <WarningIcon fontSize="small" color="warning" />
      )}
      <Typography variant="overline" color={showWarning ? 'warning.main' : undefined} fontWeight="medium">
        {lastUpdate ? dayjs(lastUpdate).format('DD.MM.YYYY, HH:mm') : 'no data'}
      </Typography>

    </>
  );
};

export default DataStateIndicator;

import { createContext } from 'react';

export type Settings = {
  settings: {
    token?: string;
    remoteApi?: string;
    localApi?: string;
    localApi2?: string;
    enableRemoteApi: boolean;
    enableLocalApi: boolean;
    enableLocalApi2: boolean;
    poolInterval: number;
  };
  setSettings: (settings: Settings['settings']) => boolean;
};

export const defaultSettingsContext: Settings = {
  setSettings: () => false,
  settings: {
    enableLocalApi: false,
    enableLocalApi2: false,
    enableRemoteApi: false,
    poolInterval: 10_000,
  },
};

const SettingsContext = createContext<Settings>(defaultSettingsContext);
SettingsContext.displayName = 'SettingsContext';

export default SettingsContext;

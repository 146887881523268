export type CameraPath = {
  host: string;
  port: string;
};

enum CameraHDStatusEnum {
  Active = 0,
  Sleep = 1,
  Exception = 2,
  SleepyHDDError =3,
  Unformatted = 4,
  Disconnected = 5, // foe network HDD
  Formatting = 6,
}

type CameraHDStatus = {
  hdNo: number; // HDD No., which starts from 1
  status: CameraHDStatusEnum; // HDD Status
  volume: number; // HDD capacity, unit: MB
  freeSpace: number; // free space, unit: MB
};

enum CameraChanStatusRecord {
  Recording = 0,
  HDDException = 1,
  NetworkCameraOfflineException = 2,
  OtherException = 3,
}

type CameraChanStatus = {
  chanNo: number; // channel No., which starts from
  online: number; // online status: 0-offline 1-online
  record: number; // whether the device is recording, 0-no, 1-yes
  recordStatus: CameraChanStatusRecord; // recording status
  signal: number; // signal status: 0-normal, 1-signal loss
  linkNum: number; // number of software clients connected to this channel
  bitRate: number; // channel bit rate, unit: Kpbs
};

export type CameraStatus = {
  devStatus: number;
  ChanStatus: CameraChanStatus[];
  HDStatus: CameraHDStatus[];
};

export type CameraDeviceInfo = {
  deviceName: string;
  deviceID: string;
  deviceDescription: string;
  deviceLocation: string;
  systemContact: string;
  model: string;
  serialNumber: string;
  macAddress: string;
  firmwareVersion: string;
  firmwareReleasedDate: string;
  encoderVersion: string;
  encoderReleasedDate: string;
  bootVersion: string;
  bootReleasedDate: number;
  hardwareVersion: number;
  deviceType: string;
  telecontrolID: number;
  supportBeep: boolean;
  supportVideoLoss: boolean;
  firmwareVersionInfo: string;
};

export type CameraNetworkInterface = {
  id: 1;
  IPAddress: {
    ipVersion: string;
    addressingType: string;
    ipAddress: string;
    subnetMask: string;
    ipv6Address: string;
    bitMask: number;
    DefaultGateway: {
      ipAddress: string;
      ipv6Address: string;
    };
    PrimaryDNS: {
      ipAddress: string;
    };
    SecondaryDNS: {
      ipAddress: string;
    };
    Ipv6Mode: {
      ipV6AddressingType: string;
      ipv6AddressList: {
        v6Address: {
          id: number;
          type: string;
          address: string;
          bitMask: number;
        };
      };
    };
  };
  Discovery: {
    UPnP: {
      enabled: boolean;
    };
    Zeroconf: {
      enabled: boolean;
    };
  };
  Link: {
    MACAddress: string;
    autoNegotiation: boolean;
    speed: number;
    duplex: string;
    MTU: number;
  };
};

export type CameraEvents = {
  responseStatus: boolean;
  numOfMatches: number;
  matchList: {
    searchMatchItem: {
      trackID: number;
      timeSpan: {
        startTime: string;
        endTime: string;
      };
      mediaSegmentDescriptor: {
        contentType: 'video';
        codecType: string;
        playbackURI: string;
      };
      metadataMatches: {
        metadataDescriptor: string;
      };
    }[];
  };
};

export type CameraMotionDetection = {
  enabled: boolean;
  enableHighlight: boolean;
  samplingInterval: number;
  startTriggerTime: number;
  endTriggerTime: number;
  regionType: string;
  Grid: {
    rowGranularity: number;
    columnGranularity: number;
  };
  MotionDetectionLayout: {
    sensitivityLevel: 0 | 20 | 40 | 60 | 80 | 100;
    layout: {
      gridMap: string | number;
    };
  };
};

/* eslint-disable camelcase */
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useDataProvider } from 'providers';
import dayjs from 'dayjs';

const boolFormat = (value: number | undefined): ReactNode => {
  if (value === 1) return <Typography variant="inherit" color="success.main">ON</Typography>;
  if (value === 0) return <Typography variant="inherit" color="warning.main">OFF</Typography>;
  return '-';
};

const tempFormat = (value: number | undefined): ReactNode => {
  return typeof value === 'number' ? `${value.toFixed(1)} °C` : '-';
};

const percentFormat = (value: number | undefined): ReactNode => {
  return typeof value === 'number' ? `${value.toFixed(0)} %` : '-';
};

const timeFormat = (value: number | undefined, format?: string): ReactNode => {
  if (!value) return '-';
  const duration = dayjs.duration(value, 'minutes');

  if (format) return duration.format(format);

  return duration
    .format('Y [Years]_M [Months]_D [Days]_H [Hours]_m [Minutes]')
    .split('_')
    .filter(x => x[0] !== '0')
    .join(' ');
};

const FieldValue: FC<{label: string; value: ReactNode}> = ({ label, value }) => (
  <>
    <Grid item xs={6}>
      <Typography variant="body2" component="div">
        {label}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body2" component="div">
        {value}
      </Typography>
    </Grid>
  </>
);

const BoilerPage: FC = () => {
  const { data } = useDataProvider();
  const { boiler_data, boiler_data_ww, heating_active, tapwater_active } = data?.EMS ?? {};

  return (
    <Grid container px={2} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Boiler
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={0.5}>
              <FieldValue label="Heating Active" value={boolFormat(heating_active)} />
              <FieldValue label="Selected Flow Temp" value={tempFormat(boiler_data?.selflowtemp)} />
              <FieldValue label="Current Flow Temp" value={tempFormat(boiler_data?.curflowtemp)} />
              <FieldValue label="Max Power" value={percentFormat(boiler_data?.burnmaxpower)} />
              <FieldValue label="Gas" value={boolFormat(boiler_data?.burngas)} />
              <FieldValue label="Pump" value={boolFormat(boiler_data?.heatingpump)} />
              <FieldValue label="Heating Activated" value={boolFormat(boiler_data?.heatingactivated)} />
              <FieldValue label="Heating Temp" value={tempFormat(boiler_data?.heatingtemp)} />
              <FieldValue label="Pump Min Power" value={percentFormat(boiler_data?.pumpmodmin)} />
              <FieldValue label="Pomp Modulation" value={percentFormat(boiler_data?.heatingpumpmod)} />
              <FieldValue label="Pump Max Power" value={percentFormat(boiler_data?.pumpmodmax)} />
              <FieldValue label="Pump Delay" value={timeFormat(boiler_data?.pumpdelay, 'm [Minutes]')} />
              <FieldValue label="Burner Min Power" value={percentFormat(boiler_data?.burnminpower)} />
              <FieldValue label="Burner Current Power" value={percentFormat(boiler_data?.curburnpow)} />
              <FieldValue label="Burner Max Power" value={percentFormat(boiler_data?.burnmaxpower)} />
              <FieldValue label="Burner Min Period" value={timeFormat(boiler_data?.burnminperiod, 'm [Minutes]')} />
              <FieldValue label="Hysteresis on Temp" value={tempFormat(boiler_data?.boilhyston)} />
              <FieldValue label="Hysteresis off Temp" value={tempFormat(boiler_data?.boilhystoff)} />
              <FieldValue label="Burner Starts" value={boiler_data?.burnstarts} />
              <FieldValue label="Heat Operating Time" value={timeFormat(boiler_data?.heatworkmin)} />
              <FieldValue label="Total Operating Time" value={timeFormat(boiler_data?.ubauptime)} />
            </Grid>
            <Typography mt={1} color="primary">Water</Typography>
            <Grid container spacing={0.5}>
              <FieldValue label="Water Heating Active" value={boolFormat(tapwater_active)} />
              <FieldValue label="Selected Temp" value={tempFormat(boiler_data_ww?.wwseltemp)} />
              <FieldValue label="Activated" value={boolFormat(boiler_data_ww?.wwactivated)} />
              <FieldValue label="Circulation Active" value={boolFormat(boiler_data_ww?.wwcirc)} />
              <FieldValue label="Circulation Pomp Available" value={boolFormat(boiler_data_ww?.wwcircpump)} />
              <FieldValue label="Circulation Pump Mode" value={boiler_data_ww?.wwcircmode} />
              <FieldValue label="3-Way Valve Active" value={boolFormat(boiler_data_ww?.ww3wayvalve)} />
              <FieldValue label="Type" value={boiler_data_ww?.wwtype} />
              <FieldValue label="Comfort" value={boiler_data_ww?.wwcomfort} />
              <FieldValue label="Flow Temp Offset" value={tempFormat(boiler_data_ww?.wwflowtempoffset)} />
              <FieldValue label="Charging Type" value={boiler_data_ww?.wwchargetype} />
              <FieldValue label="Hysteresis on Temp" value={tempFormat(boiler_data_ww?.wwhyston)} />
              <FieldValue label="Hysteresis off Temp" value={tempFormat(boiler_data_ww?.wwhystoff)} />
              <FieldValue label="Disinfection Temp" value={tempFormat(boiler_data_ww?.wwdisinfectiontemp)} />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default BoilerPage;

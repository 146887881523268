import { useTheme } from '@table-library/react-table-library/theme';

export const useTableTheme = ({ grid } : { grid: string }) => {
  const commonTheme = useTheme({
    BaseRow: `
      font-size: 14px;
    `,
    HeaderRow: `
      text-transform: uppercase;
      background-color: black;
      color: #90CAF9;

      .th {
        border-bottom: 1px solid #565656;
      }
    `,
    Row: `
      background-color: #1e1e1e;
      position: relative;
      cursor: pointer;

      .td {
        padding: 8px;
        border-top: 1px solid #565656;
        border-bottom: 1px solid #565656;
      }

      &.tr.tr-body.row-select.row-select-single-selected {
        background-color: #3d4752;
        color: white;
        font-weight: normal;
      }

      &:hover .td {
        border-top: 1px solid #177ac9;
        border-bottom: 1px solid #177ac9;
      }
    `,
    Cell: `
      &:last-of-type {
        text-align: right;
      },
    `,
  });

  const deviceTheme = useTheme([
    commonTheme,
    {
      Table: `
        --data-table-library_grid-template-columns: ${grid} !important;
      `,
      BaseRow: `
        .td {
          height: 42px;
        }
      `,
      HeaderRow: `
        .th {
          padding: 8px;
          height: 42px;
          font-weight: 500;
      `,
    },
  ]);

  return deviceTheme;
};

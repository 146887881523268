import { object, string, ObjectSchema, number } from 'yup';
import { Settings } from 'providers/SettingsProvider';

export type SETTINGS_FIELDS = Required<Settings['settings']>;

export const settingSchema: ObjectSchema<{}> = object({
  token: string()
    .trim()
    .required(),
  remoteApi: string()
    .trim()
    .url()
    .required(),
  localApi: string()
    .trim()
    .url()
    .required(),
  localApi2: string()
    .trim()
    .url(),
  poolInterval: number()
    .min(5)
    .max(60)
    .required(),
});

export const toSettingsFormModel = ({ token, remoteApi, localApi, localApi2, enableLocalApi, enableLocalApi2, enableRemoteApi, poolInterval }: Settings['settings']): SETTINGS_FIELDS => ({
  token: token ?? '',
  remoteApi: remoteApi ?? '',
  localApi: localApi ?? '',
  localApi2: localApi2 ?? '',
  enableLocalApi: enableLocalApi ?? false,
  enableLocalApi2: enableLocalApi2 ?? false,
  enableRemoteApi: enableRemoteApi ?? false,
  poolInterval: poolInterval ? poolInterval / 1000 : 10,
});

export const toAppSettings = ({ token, remoteApi, localApi, localApi2, enableLocalApi, enableLocalApi2, enableRemoteApi, poolInterval }: SETTINGS_FIELDS): Settings['settings'] => ({
  token, remoteApi, localApi, localApi2, enableLocalApi, enableLocalApi2, enableRemoteApi, poolInterval: poolInterval * 1000,
});

import { Card, CardContent, Typography, Grid } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import { useDataProvider } from 'providers';
import dayjs from 'dayjs';
import { UpsStatusValue } from '@smart-home/common';

const statusFormat = <T extends string>(value: T | undefined, ok: T[]): ReactNode => {
  if (value && ok.includes(value)) {
    return <Typography variant="inherit" color="success.main">{value}</Typography>;
  } else if (value) {
    return <Typography variant="inherit" color="warning.main">{value}</Typography>;
  }
  return '-';
};

const tempFormat = (value: number | undefined): ReactNode => {
  return typeof value === 'number' ? `${value.toFixed(1)} °C` : '-';
};

const percentFormat = (value: number | undefined): ReactNode => {
  return typeof value === 'number' ? `${value.toFixed(1)} %` : '-';
};

const voltageFormat = (value: number | undefined): ReactNode => {
  return typeof value === 'number' ? `${value.toFixed(1)} V` : '-';
};

const currentFormat = (value: number | undefined): ReactNode => {
  return typeof value === 'number' ? `${value.toFixed(1)} A` : '-';
};

const timeFormat = (value: number | undefined, format?: string): ReactNode => {
  if (!value) return '-';
  const duration = dayjs.duration(value, 'minutes');

  if (format) return duration.format(format);

  return duration
    .format('Y [Years]_M [Months]_D [Days]_H [Hours]_m [Minutes]')
    .split('_')
    .filter(x => x[0] !== '0')
    .join(' ');
};

const dateFormat = (value: string | undefined, format: string) => {
  if (!value) return '-';

  return dayjs(value).format(format);
};

const FieldValue: FC<{label: string; value: ReactNode}> = ({ label, value }) => (
  <>
    <Grid item xs={7}>
      <Typography variant="body2" component="div">
        {label}
      </Typography>
    </Grid>
    <Grid item xs={5}>
      <Typography variant="body2" component="div">
        {value}
      </Typography>
    </Grid>
  </>
);

const getValues = (data: UpsStatusValue) => {
  const buffer: { id: keyof UpsStatusValue; label: string; value: ReactNode }[][] = [[
    { id: 'STATUS', label: 'Status', value: statusFormat(data.STATUS ?? 'N/A', ['ONLINE']) },
    { id: 'LINEV', label: 'Input Voltage', value: voltageFormat(data.LINEV) },
    { id: 'OUTPUTV', label: 'Output Voltage', value: voltageFormat(data.OUTPUTV) },
    { id: 'LINEFREQ', label: 'Current Line Frequency', value: data.LINEFREQ ? `${data.LINEFREQ} Hz` : '-' },
    { id: 'OUTCURNT', label: 'Output Current', value: currentFormat(data.OUTCURNT) },
    { id: 'SELFTEST', label: 'Self Test Status', value: statusFormat(data.SELFTEST, ['OK']) },
    { id: 'BATTV', label: 'Battery Voltage', value: voltageFormat(data.BATTV) },
    { id: 'BCHARGE', label: 'Battery Capacity', value: percentFormat(data.BCHARGE) },
    { id: 'TIMELEFT', label: 'Remaining time on battery', value: timeFormat(data.TIMELEFT) },
    { id: 'TONBATT', label: 'Seconds on battery', value: data.TONBATT },
    { id: 'CUMONBATT', label: 'Total Seconds on battery', value: data.CUMONBATT },
    { id: 'XOFFBATT', label: 'Last transfer off battery', value: (data.XOFFBATT?.length >= 10 ? dateFormat(data.XOFFBATT, 'DD/MM/YYYY HH:mm') : data.XOFFBATT) },
    { id: 'ITEMP', label: 'Internal Temperature', value: tempFormat(data.ITEMP) },
    { id: 'BATTDATE', label: 'Battery Replaced', value: dateFormat(data.BATTDATE, 'DD/MM/YYYY') },
    { id: 'NOMOUTV', label: 'Output Voltage (on battery)', value: voltageFormat(data.NOMOUTV) },
    { id: 'NUMXFERS', label: 'Transfers to Battery', value: data.NUMXFERS },
    { id: 'STARTTIME', label: 'Start Date', value: dateFormat(data.STARTTIME, 'DD/MM/YYYY HH:mm') },
    { id: 'UPSNAME', label: 'UPS Name', value: data.UPSNAME },
    { id: 'MODEL', label: 'Model', value: data.MODEL },
    { id: 'NOMPOWER', label: 'Output Power (Watts)', value: data.NOMPOWER },
    { id: 'NOMAPNT', label: 'Output Power (VA)', value: data.NOMAPNT },
    { id: 'CABLE', label: 'Cable Type', value: data.CABLE },
    { id: 'DRIVER', label: 'Driver', value: data.DRIVER },
    { id: 'SERIALNO', label: 'Serial Number', value: data.SERIALNO },
    { id: 'FIRMWARE', label: 'Firmware', value: data.FIRMWARE },
    { id: 'MANDATE', label: 'Date of Manufacture', value: dateFormat(data.MANDATE, 'DD/MM/YYYY') },
    { id: 'STATFLAG', label: 'Status Flag', value: data.STATFLAG },
  ], []];

  Object.entries(data).forEach(([key, value]) => {
    if (!buffer[0].find(x => x.id === key)) {
      buffer[1].push({ id: key as keyof UpsStatusValue, label: key, value: value?.toString() });
    }
  });

  return buffer;
};

const PowerPage: FC = () => {
  const { data } = useDataProvider();
  const status = data?.UPS?.[0]?.value;
  const [mainItems, secondaryItems] = useMemo(() => getValues(status ?? {} as any), [status]);

  return (
    <Grid container px={2} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Power
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={0.5}>
              {mainItems.map(x => (
                <FieldValue key={x.label} label={x.label} value={x.value} />
              ))}
            </Grid>
            {secondaryItems.length > 0 && (
              <>
                <Typography mt={1} color="primary">Extra data</Typography>
                <Grid container spacing={0.5}>
                  {secondaryItems.map(x => (
                    <FieldValue key={x.label} label={x.label} value={x.value} />
                  ))}
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PowerPage;

import { FC, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { router as homeRouter } from './routes.home';
import { router as playerRouter } from './routes.player';
import { SnackbarProvider } from 'notistack';
import { ConnectionProvider } from './providers/ConnectionProvider';
import { DataProvider } from './providers/DataProvider/DataProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { getTheme } from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const isPlayerMode = process.env.REACT_APP_PLAYER_MODE === 'true';

const App: FC = () => {
  const theme = useMemo(() => getTheme('dark'), []);
  const router = isPlayerMode ? playerRouter : homeRouter;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3} disableWindowBlurListener autoHideDuration={1000} dense anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      >
        <QueryClientProvider client={queryClient}>
          <ConnectionProvider>
            <DataProvider>
              <RouterProvider router={router} />
            </DataProvider>
          </ConnectionProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;

import { Favorite, MoreVert, PlayCircle } from '@mui/icons-material';
import { Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { MediaItem } from '@smart-home/common';
import { FC, useCallback, useState } from 'react';

export type VideoMenuProps = {
  onAction: (type: 'play' | 'favorite', media: MediaItem) => void;
  data: MediaItem;
};

const VideoMenu: FC<VideoMenuProps> = ({ onAction, data }) => {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <IconButton onClick={toggle} >
        <MoreVert />
      </IconButton>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={toggle}
      >
        <Box
          sx={{ width: 'auto', pb: 5 }}
          role="presentation"
          onClick={toggle}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => onAction('play', data) }>
                <ListItemIcon>
                  <PlayCircle />
                </ListItemIcon>
                <ListItemText primary="Play" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding onClick={() => onAction('favorite', data) }>
              <ListItemButton>
                <ListItemIcon>
                  <Favorite />
                </ListItemIcon>
                <ListItemText primary="Add to Favorites" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default VideoMenu;

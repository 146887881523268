import dayjs from 'dayjs';
import { FC } from 'react';

type UpdatedAtProps = {
  date?: string;
};

const UpdatedAt: FC<UpdatedAtProps> = ({ date }) => {
  return (
    <>
      {date ? dayjs(date).fromNow() : '-'}
    </>
  );
};

export default UpdatedAt;

import { Card, CardContent, Typography, Grid } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useDataProvider } from 'providers';
import { useQuery } from 'react-query';
import axios from 'axios';
import { CameraEvents } from '@smart-home/common';
import { useConnection } from 'providers/ConnectionProvider';
import dayjs from 'dayjs';

const statusFormat = <T extends string>(value: T | undefined, ok: T[]): ReactNode => {
  if (value && ok.includes(value)) {
    return <Typography variant="inherit" color="success.main">{value}</Typography>;
  } else if (value) {
    return <Typography variant="inherit" color="warning.main">{value}</Typography>;
  }
  return '-';
};

const FieldValue: FC<{label: string; value: ReactNode}> = ({ label, value }) => (
  <>
    <Grid item xs={9}>
      <Typography variant="body2" component="div">
        {label}
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <Typography variant="body2" component="div">
        {value}
      </Typography>
    </Grid>
  </>
);

const SurveillancePage: FC = () => {
  const { apiUrl } = useConnection();
  const { data } = useDataProvider();

  const cameras = data?.CAMERA ?? [];

  const cameraAlias = cameras.find(x => x.value.status === 'online');
  const { data: eventsData } = useQuery('events', () => axios.get<CameraEvents>(
    `${apiUrl}/surveillance/camera/events?alias=${cameraAlias?.value.name}&days=100`,
  ).then(x => x.data), {
    enabled: !!(apiUrl && cameraAlias),
  });

  return (
    <Grid container px={2} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Cameras
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={0.5}>
              {cameras.map(x => (
                <FieldValue key={x.id} label={`${x.value.name} (${x.value.path})`} value={statusFormat(x.value.status, ['online'])} />
              ))}
              {cameras.length === 0 && (
                <Grid item xs={12}>
                  <Typography>no cameras info</Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
        {!!eventsData?.numOfMatches && <Card>
          <CardContent>
            {[...eventsData.matchList.searchMatchItem].reverse().map((x, i) => (
              <Typography key={x.timeSpan.startTime}>
                {i + 1}) {dayjs(x.timeSpan.startTime).format('DD-MM-YY / HH:mm:ss')} - {dayjs(x.timeSpan.endTime).diff(dayjs(x.timeSpan.startTime), 'seconds')} sec
              </Typography>
            ))}
          </CardContent>
        </Card>}
      </Grid>
    </Grid>
  );
};

export default SurveillancePage;

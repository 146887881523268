/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { ElementType, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, SubmitHandler, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { ElementWithProps } from '../types';

export type DialogComponentProps<TFieldValues extends FieldValues, TFooterProps = any> = {
  title: ReactNode;
  formComponent?: ElementType;
  footer?: ElementWithProps<TFooterProps>;
  open: DialogProps['open'];
  onClose: (e?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onSubmit: SubmitHandler<TFieldValues>;
  formHandlers: UseFormReturn<TFieldValues>;
} & Omit<DialogProps, 'open' | 'onSubmit'>;

const DialogComponent = <TFieldValues extends FieldValues, TFooterProps = any>(allProps: DialogComponentProps<TFieldValues, TFooterProps>) => {
  const { title, formComponent: FormComponent, onClose, onSubmit, formHandlers, footer, ...dialogProps } = allProps;

  const Footer: ElementType<unknown> | null = footer ? footer[0] as ElementType<unknown> : null;
  const footerProps = footer ? footer[1] : null;

  return (
    <Dialog {...dialogProps} onClose={onClose}>
      <form onSubmit={formHandlers.handleSubmit(onSubmit)} autoComplete="false">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important' }}>
          {FormComponent && <FormComponent {...formHandlers} />}
        </DialogContent>
        <DialogActions>
          {Footer && (
            <Box flexGrow={1}>
              <Footer {...footerProps} />
            </Box>
          )}
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const useFormDialog = <
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TFooterProps = any>(props: UseFormProps<TFieldValues>, onSubmitForm: (formValues: TFieldValues) => Promise<unknown>): [
    ElementWithProps<DialogComponentProps<TFieldValues, TFooterProps>>, (defaultValues: TFieldValues) => void,
    UseFormReturn<TFieldValues> & { onClose: () => void }
  ] => {
  const [open, setOpen] = useState(false);

  const formHandlers = useForm<TFieldValues>(props);
  const { reset } = formHandlers;

  const showDialog = useCallback((defaultValues: TFieldValues) => {
    reset(defaultValues);
    setOpen(true);
  }, [reset]);

  const handleClose = useCallback((_?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  }, []);

  const onSubmit = useCallback<SubmitHandler<TFieldValues>>(async (formValues) => {
    await onSubmitForm(formValues);
    setOpen(false);
  }, [onSubmitForm]);

  const dialogComponentProps = useMemo<DialogComponentProps<TFieldValues, TFooterProps>>(() => ({
    title: '',
    open,
    formHandlers,
    onSubmit,
    onClose: handleClose,
  }), [open, formHandlers, onSubmit, handleClose]);

  return [[DialogComponent, dialogComponentProps], showDialog, { ...formHandlers, onClose: handleClose }];
};

import axios from 'axios';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSettings } from '../SettingsProvider/useSettings';
import ConnectionContext, { ConnectionStatus } from './ConnectionContext';

export type DataProviderProps = {
  children: JSX.Element | JSX.Element[];
};

const ping = (api: string) => axios.get<{ status: string }>(`${api}/health`, { timeout: 3000 })
  .then(({ data }) => data.status === 'ok')
  .catch(() => false);

export const ConnectionProvider: FC<DataProviderProps> = ({ children }) => {
  const [status, setStatus] = useState<ConnectionStatus>('offline');
  const [connectInProgress, setConnectInProgress] = useState(false);
  const [wsStatus, setWsStatus] = useState<boolean>(false);
  const [apiUrl, setApiUrl] = useState<string | null>(null);
  const { settings: { localApi, localApi2, remoteApi, enableLocalApi, enableRemoteApi, enableLocalApi2 } } = useSettings();

  const checkConnection = useCallback(async (): Promise<ConnectionStatus> => {
    if (navigator.onLine) {
      if (enableLocalApi && localApi) {
        setConnectInProgress(true);
        const ok = await ping(localApi);
        setConnectInProgress(false);
        if (ok) {
          setStatus('direct');
          setApiUrl(localApi);
          return 'direct';
        }
      }
      if (enableLocalApi2 && localApi2) {
        setConnectInProgress(true);
        const ok = await ping(localApi2);
        setConnectInProgress(false);
        if (ok) {
          setStatus('direct');
          setApiUrl(localApi2);
          return 'direct';
        }
      }
      if (enableRemoteApi && remoteApi) {
        setConnectInProgress(true);
        const ok = await ping(remoteApi);
        setConnectInProgress(false);
        if (ok) {
          setStatus('online');
          setApiUrl(null);
          return 'online';
        }
      }
    }
    setApiUrl(null);
    setStatus('offline');
    return 'offline';
  }, [localApi, remoteApi, enableLocalApi, enableRemoteApi, enableLocalApi2, localApi2]);

  useEffect(() => {
    window.addEventListener('online', checkConnection);
    window.addEventListener('offline', checkConnection);

    return () => {
      window.removeEventListener('online', checkConnection);
      window.removeEventListener('offline', checkConnection);
    };
  }, [checkConnection]);

  useEffect(() => {
    checkConnection();
  }, [checkConnection]);

  return (
    <ConnectionContext.Provider value={{ status, apiUrl, checkConnection, setWsStatus, wsStatus, connectInProgress }}>
      {children}
    </ConnectionContext.Provider>
  );
};

import dayjs from 'dayjs';
import { FC, useCallback } from 'react';
import { useDataProvider } from 'providers';
import { Card, CardContent, Typography, Grid, Stack } from '@mui/material';
import Temperature from './components/Temperature';
import { TempSensorValue, AccessoryDetailValue, TEMP_SENSOR, XIAOMI_TEMP_SENSOR } from '@smart-home/common';
import { LocalFireDepartment, LocalFireDepartmentOutlined } from '@mui/icons-material';
import { XiaomiTempSensorValue } from '@smart-home/common/src/accessories/xiaomi-temp-sensor';

type SensorValueProps = {
  label: string;
  value?: number;
};

/**
 Sensor 1 : (40,192,16,72,246,122,60,17) 0x28C01048F67A3C11
 Sensor 2 : (40,93,221,72,246,5,60,138)  0x285DDD48F6053C8A
 Sensor 3 : (40,254,35,72,246,12,60,57)  0x28FE2348F60C3C39
 Sensor 4 : (40,145,219,118,224,1,60,9)  0x2891DB76E0013C09
 Sensor 5 : (40,43,194,149,240,1,60,197) 0x282BC295F0013CC5
 */

const SensorValue: FC<SensorValueProps> = ({ label, value }) => {
  return (
    <Typography>
      {label}: <Temperature value={value} />
    </Typography>
  );
};

const SensorsPage: FC = () => {
  const { data } = useDataProvider();

  const getSensorValue = useCallback((id: string): AccessoryDetailValue<TempSensorValue> | undefined => {
    return data?.[TEMP_SENSOR]?.find(x => x.id === id) as AccessoryDetailValue<TempSensorValue>;
  }, [data]);

  const getZigbeeSensorValue = useCallback((id: string): AccessoryDetailValue<XiaomiTempSensorValue> | undefined => {
    return data?.[XIAOMI_TEMP_SENSOR]?.find(x => x.id === id) as AccessoryDetailValue<XiaomiTempSensorValue>;
  }, [data]);

  const boilerRelay = data?.SOLID_RELAY?.find(x => x.id === '15');
  const isBoilerEnabled = boilerRelay?.value === true;
  const lastBoilerUpdate = boilerRelay?.updatedAt;

  return (
    <Grid container px={2} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Sensors data
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="overline" component="div">
              Warm floor
            </Typography>
            <SensorValue label="Enter" value={getSensorValue('0x28C01048F67A3C11')?.value} />
            <SensorValue label="Return" value={getSensorValue('0x285DDD48F6053C8A')?.value} />

            <Typography sx={{ mt: 2 }} variant="overline" component="div">
              Radiators
            </Typography>
            <SensorValue label="Enter" value={getSensorValue('0x282BC295F0013CC5')?.value} />
            <SensorValue label="Return" value={getSensorValue('0x28FE2348F60C3C39')?.value} />

            <Typography sx={{ mt: 2 }} variant="overline" component="div">
              Inside
            </Typography>
            <SensorValue label="Boiler room" value={getSensorValue('0x2891DB76E0013C09')?.value} />
            <SensorValue label="Lounge" value={getZigbeeSensorValue('0xa4c138849950002b')?.value.temperature} />
            <SensorValue label="Garage" value={getZigbeeSensorValue('0xa4c13895cff9e6e7')?.value.temperature} />

            <Typography sx={{ mt: 2 }} variant="overline" component="div">
              Outside
            </Typography>
            <SensorValue label="Outdoors" value={getSensorValue('0x28F15A979407032E')?.value} />
            <Stack direction="row" mt={2} alignItems="center">
              <Typography pr={1}>Boiler:</Typography>
              {isBoilerEnabled
                ? <LocalFireDepartment color="warning" />
                : <LocalFireDepartmentOutlined />}
              <Typography variant="body2" color={isBoilerEnabled ? 'orange' : undefined} pl={0.5}>
                {lastBoilerUpdate
                  ? `${dayjs(new Date()).diff(lastBoilerUpdate, 'minutes')} minutes ago`
                  : 'unknown'}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SensorsPage;

import { FC, useMemo } from 'react';
import { Table as RtlTable, TableProps as RtlTableProps, TableNode, Header, HeaderRow, HeaderCell, Body, Row, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { useSort, SortToggleType } from '@table-library/react-table-library/sort';
import { useRowSelect } from '@table-library/react-table-library/select';
import { useTableTheme } from './useTableTheme';

type TableRow = {
  key?: string;
  id: string;
};

type TableProps<T extends TableRow> = {
  grid: string;
  data: T[];
  headers: React.ReactNode;
  row: React.ElementType<{row: T}>;
} & Omit<RtlTableProps, 'data'>;

const Table = <T extends TableRow>({ grid, data, headers, row: RowComponent, ...props }: TableProps<T>) => {
  const theme = useTableTheme({ grid });
  // const nodes = useMemo<TableNode[]>(() => data.map(x => ({ ...x, id})), []);

  return (
    <RtlTable data={{ nodes: data }} theme={theme} layout={{ custom: true }} {...props}>
      {(list) => (
        <>
          <Header>
            <HeaderRow>
              {headers}
            </HeaderRow>
          </Header>
          <Body>
            {(list as T[]).map((row) => (
              <Row key={row.id} item={row}>
                <RowComponent row={row} />
              </Row>
            ))}
          </Body>
        </>
      )}
    </RtlTable>
  );
};

export default Table;

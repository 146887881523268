import { LocalFireDepartment, LocalFireDepartmentOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { AccessoryDetailValue, BoilerStatistic } from '@smart-home/common';
import { SolidRelayValue } from '@smart-home/common/src/accessories/solid-relay';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';

type BoilerStatisticProps = {
  data?: BoilerStatistic['data'];
  relay?: AccessoryDetailValue<SolidRelayValue>;
};

const getDate = (min: number) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + min);
  return date.toISOString();
};

const BoilerStatisticChart: FC<BoilerStatisticProps> = ({ data, relay }) => {
  const stat = useMemo(() => {
    const now = new Date();
    return data?.filter(x => now.getTime() - new Date(x.date).getTime() < 24 * 60 * 60 * 1000);
  }, [data]);

  if (!stat) return null;

  const utilization = stat.length && stat.length > 2 ? Math.round(((
    stat[stat.length - 1].heatworkmin! - stat[0].heatworkmin!
  ) / (
    dayjs(stat[stat.length - 1].date).diff(stat[0].date, 'minutes')
  )) * 100) : null;

  const lastStart = stat[stat.length - 2]?.date;

  return (
    <Box width={300}>
      <Stack direction="row" alignItems="end" justifyContent="flex-end">
        {stat?.map((x, i) => (
          <Box key={x.date} style={{
            [x.isActive ? 'borderTop' : 'borderBottom']: i === 0 ? undefined : '1px solid #90caf9',
            borderLeft: i === 0 ? undefined : '1px solid #90caf9',
            width: i === 0 ? 1 : Math.round((new Date(x.date).getTime() - new Date(stat[i - 1].date).getTime()) / (5 * 60 * 1000)),
            height: 30,
          }}
          />
        ))}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption">
          {stat[stat.length - 1].isActive
            ? <LocalFireDepartment fontSize="small" color="warning" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
            : <LocalFireDepartmentOutlined fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />}
          {lastStart ? <>{dayjs(new Date()).diff(lastStart, 'minutes')} minutes ago</> : 'unknown'}
        </Typography>
        {utilization !== null && <Typography variant="caption">
          Utilization: <b>{utilization}%</b>
        </Typography>}
      </Stack>
    </Box>
  );
};

export default BoilerStatisticChart;

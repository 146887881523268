import { Box } from '@mui/material';
import { CSSProperties, FC, PropsWithChildren } from 'react';

type PageVisibilityProps = {
  visible: boolean;
} & PropsWithChildren;

const PageVisibility: FC<PageVisibilityProps> = ({ visible, children }) => {
  const style: CSSProperties | undefined = visible ? undefined : {
    position: 'absolute',
    top: -10000,
  };

  return (
    <Box style={style}>
      {children}
    </Box>
  );
};

export default PageVisibility;

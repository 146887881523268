import { Typography, Grid, LinearProgress, Box, TextField, IconButton, ToggleButtonGroup, ToggleButton, Divider, CircularProgress } from '@mui/material';
import { PlayerCommand, MediaItem } from '@smart-home/common';
import { FC, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useDataProvider } from 'providers';
import { VideoMenuProps } from './VideoMenu';
import axios from 'axios';
import { useMutation } from 'react-query';
import { Clear, Search } from '@mui/icons-material';
import { addVideo } from './LibrarySubPage';
import { useConnection } from 'providers/ConnectionProvider';
import MediaItemsList from './MediaItemsList';

const addSearch = (value: string) => {
  let items = JSON.parse(localStorage.getItem('SEARCH') ?? '[]') as string[];
  items = items.filter(x => x.localeCompare(value, undefined, { sensitivity: 'base' }));
  items = [value, ...items].slice(0, 10);
  localStorage.setItem('SEARCH', JSON.stringify(items));
  return items;
};

const SearchSubPage: FC = () => {
  const [selectedVideo, setSelectedVideo] = useState<MediaItem>();
  const { playerData, sendWsMessage } = useDataProvider();
  const connection = useConnection();

  const [searches, setSearches] = useState(JSON.parse(localStorage.getItem('SEARCH') ?? '[]') as string[]);

  const { status, source } = playerData;

  useEffect(() => {
    setSelectedVideo(source);
  }, [source]);

  const sendPlayerCommand = useCallback((data: PlayerCommand) => {
    sendWsMessage('player/command', data);
  }, [sendWsMessage]);

  const inputRef = useRef<HTMLInputElement>();

  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState<{items: MediaItem[]; nextPage?: unknown}>({ items: [] });

  const { mutateAsync: searchByKeyword, isLoading: isSearching } = useMutation<{items: MediaItem[]; nextPage: unknown}, unknown, { next: boolean }>(async () =>
    axios.post(`${connection.apiUrl}/player/search/youtube`, { keywords: keyword, nextPage: results.nextPage }).then(x => x.data), {
    onSuccess: (data, options) => {
      setResults({
        items: ((options.next
          ? [
            ...results.items,
            ...data.items.filter(x => !results.items.some(r => r.id === x.id)),
          ]
          : data.items)).filter(Boolean),
        nextPage: data.nextPage,
      });
    },
  });

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(async (e) => {
    if (e.key === 'Enter') {
      setResults({ items: [] });
      const res = await searchByKeyword({ next: false });
      if (res?.items?.length) {
        setSearches(addSearch(keyword));
        if (navigator.platform === 'iPhone') {
          (e.target as any).blur();
        }
      }
    }
  }, [searchByKeyword, keyword]);

  const onAction = useCallback<VideoMenuProps['onAction']>((type, media) => {
    if (type === 'play') {
      setSelectedVideo(media);
      sendPlayerCommand({ type, media, next: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] });
    } else if (type === 'favorite') {
      addVideo(media);
    }
  }, [sendPlayerCommand]);

  const onScroll = (e: any) => {
    const target = e.target as HTMLDivElement;
    const distance = target.scrollHeight - target.clientHeight - target.scrollTop;
    if (!isSearching && results.items.length && distance < 100) {
      searchByKeyword({ next: true });
    }
  };

  return (
    <Grid container spacing={1} mt={0.5}>
      <Grid item xs={12}>
        <TextField
          inputRef={inputRef}
          fullWidth
          onKeyDown={onKeyDown}
          value={keyword}
          onChange={e => setKeyword(e.target.value)}
          autoComplete="off"
          variant="filled"
          placeholder="Search..."
          inputProps={{
            style: {
              paddingTop: 8,
            },
          }}
          InputProps={{
            startAdornment: (
              <Search sx={{ mr: 1 }} />
            ),
            endAdornment: (
              <IconButton
                sx={{ visibility: keyword ? 'visible' : 'hidden' }}
                onClick={() => {
                  setKeyword('');
                  inputRef.current?.focus();
                  setResults({ items: [] });
                }}
              >
                <Clear />
              </IconButton>
            ),
          }}
        />
        <LinearProgress sx={{ visibility: isSearching && !results.items.length ? 'visible' : 'hidden' }} />
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup size="small" value="video" exclusive sx={{ height: 30, mb: 1, justifyContent: 'center', display: 'flex' }}>
          <ToggleButton value="video" sx={{ py: 0 }}>
            Video
          </ToggleButton>
          <ToggleButton value="playlist">
            Playlist
          </ToggleButton>
          <ToggleButton value="channel">
            channel
          </ToggleButton>
        </ToggleButtonGroup>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box maxHeight="calc(100vh - 190px)" overflow="auto" py={0.5} onScroll={onScroll}>
          {!keyword && results.items.length === 0 && (
            <Box px={2}>
              {searches.map(x => (
                <Typography fontSize={20} key={x} sx={{ textDecoration: 'underline', cursor: 'pointer', mb: 1 }} onClick={() => {
                  setKeyword(x);
                  setTimeout(() => searchByKeyword({ next: false }));
                }}
                >
                  {x}
                </Typography>
              ))}
            </Box>
          )}
          <MediaItemsList
            items={results.items}
            onAction={onAction}
            statusState={status?.state}
            selectedMediaId={selectedVideo?.id}
            currentMediaId={source?.id}
          />
          {!!results.items.length && isSearching && <CircularProgress sx={{ display: 'block', margin: '8px auto' }} />}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchSubPage;

import { FC } from 'react';

type HumidityProps = {
  value?: number;
};

const Humidity: FC<HumidityProps> = ({ value }) => {
  return (
    <>
      {typeof value === 'number' ? `${value.toFixed(0)} %` : '-'}
    </>
  );
};

export default Humidity;

import { Edit } from '@mui/icons-material';
import { IconButton, Switch } from '@mui/material';
import { Accessory } from '@smart-home/common';
import { FC } from 'react';
import Table, { Cell, Header } from 'components/Table';
import { useDataProvider } from '../../../../providers';
import { useUpdateAccessory } from '../../hooks/useUpdateAccessory';
import { AccessoryProps } from '../accessories.types';
import RelayAccessoryForm from './RelayAccessory.form';

type RelayControlProps = {
  state?: boolean;
  onToggle?: (value: boolean) => void;
};

const RelayControl: FC<RelayControlProps> = ({ state, onToggle }) => {
  if (typeof state === 'undefined') {
    return <>-</>;
  }
  return (
    <Switch
      checked={state}
      size="small"
      onChange={() => onToggle?.(!state)}
    />
  );
};

const RelayAccessoryTable: FC<AccessoryProps> = ({ accessories, data }) => {
  const [[UpdateForm, updateFormProps], onUpdateAccessory] = useUpdateAccessory<Accessory>();
  const { sendMqttMessage } = useDataProvider();

  return (
    <>
      <Table
        grid="100px repeat(1, minmax(0, 1fr)) 80px 60px"
        data={accessories}
        headers={<>
          <Header stiff>ALIAS</Header>
          <Header resize>TOPIC</Header>
          <Header resize>STATE</Header>
          <Header />
        </>}
        row={({ row }) => (
          <>
            <Cell stiff>{row.alias}</Cell>
            <Cell>{`${row.topic}/${row.id}`}</Cell>
            <Cell>
              <RelayControl
                state={data?.SOLID_RELAY?.find(x => x.id === row.id)?.value}
                onToggle={(newValue) => sendMqttMessage(`${row.topic}/${row.id}/set`, newValue ? '1' : '0')}
              />
            </Cell>
            <Cell onClick={e => e.stopPropagation()}>
              <IconButton onClick={() => onUpdateAccessory({
                values: row,
              })}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Cell>
          </>
        )}
      />
      <UpdateForm {...updateFormProps} formComponent={RelayAccessoryForm} />
    </>
  );
};

export default RelayAccessoryTable;

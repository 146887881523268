import { Button, Stack, Typography, Grid, LinearProgress } from '@mui/material';
import { FC } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ConnectionIndicator from '../ConnectionIndicator';
import { useGoto } from '../../../../hooks/useGoto';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../../assets/logo192.png';
import DataStateIndicator from '../DataStateIndicator';
import { useConnection } from '../../../../providers/ConnectionProvider';

const AppHeader: FC = () => {
  const gotoHome = useGoto('home');
  const navigate = useNavigate();

  const { pathname, state } = useLocation();
  const { connectInProgress } = useConnection();

  return (
    <>
      <Grid container p={1}>
        <Grid item xs={6}>
          {pathname !== '/home' ? (
            <Button onClick={() => state?.backUrl ? navigate(state.backUrl) : gotoHome()}>
              <ArrowBack />
              Back
            </Button>
          ) : (
            <Typography variant="h6">
              <img src={logo} height={32} style={{ verticalAlign: 'top' }} /> Smarthome
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={0.5}>
            <ConnectionIndicator />
            <DataStateIndicator />
          </Stack>
        </Grid>
      </Grid>
      <LinearProgress style={{ visibility: connectInProgress ? 'visible' : 'hidden' }} />
    </>
  );
};

export default AppHeader;

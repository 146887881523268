import { Grid, TextField, TextFieldProps } from '@mui/material';
import { AccessoryType } from '@smart-home/common';
import { FC } from 'react';
import { FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form';

type BoilerAccessoryProps = {
  field?: string;
} & UseFormReturn<BoilerAccessoryFormModel>;

const muiErrors = <T extends FieldValues>(errors: FieldErrors<T>, name: keyof T): Pick<TextFieldProps, 'error' | 'helperText'> => ({
  error: !!errors[name],
  helperText: errors[name]?.message as string,
});

export type BoilerAccessoryFormModel = {
  alias: string;
  topic: string;
  id: string;
  type: AccessoryType;
};

const BoilerAccessoryForm: FC<BoilerAccessoryProps> = (props) => {
  const { register, formState: { errors } } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Alias"
          fullWidth
          error={!!errors.alias}
          helperText={errors.alias?.message}
          autoComplete="off"
          {...muiErrors(errors, 'alias')}
          {...register('alias')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Topic"
          fullWidth
          error={!!errors.topic}
          helperText={errors.topic?.message}
          autoComplete="off"
          disabled
          {...muiErrors(errors, 'topic')}
          {...register('topic')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Id"
          fullWidth
          error={!!errors.id}
          helperText={errors.id?.message}
          autoComplete="off"
          disabled
          {...muiErrors(errors, 'id')}
          {...register('id')}
        />
      </Grid>
    </Grid>
  );
};

export default BoilerAccessoryForm;
